* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "montserratregular";
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #a0a0a0;
}

@font-face {
  font-family: "montserratblack";
  src: url("../fonts/montserrat-black-webfont.woff2") format("woff2"),
    url("../fonts/montserrat-black-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "montserratbold";
  src: url("../fonts/montserrat-bold-webfont.woff2") format("woff2"),
    url("../fonts/montserrat-bold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "montserratextrabold";
  src: url("../fonts/montserrat-extrabold-webfont.woff2") format("woff2"),
    url("../fonts/montserrat-extrabold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "montserratlight";
  src: url("../fonts/montserrat-light-webfont.woff2") format("woff2"),
    url("../fonts/montserrat-light-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "montserratmedium";
  src: url("../fonts/montserrat-medium-webfont.woff2") format("woff2"),
    url("../fonts/montserrat-medium-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "montserratregular";
  src: url("../fonts/montserrat-regular-webfont.woff2") format("woff2"),
    url("../fonts/montserrat-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "montserratsemibold";
  src: url("../fonts/montserrat-semibold-webfont.woff2") format("woff2"),
    url("../fonts/montserrat-semibold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "montserratbold";
  src: url("../fonts/montserrat-semibold-webfont.woff2") format("woff2"),
    url("../fonts/montserrat-semibold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "montserratthin_italic";
  src: url("../fonts/montserrat-thinitalic-webfont.woff2") format("woff2"),
    url("../fonts/montserrat-thinitalic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

/* @font-face {
  font-family: 'founders_groteskmedium';
  src: url("https://db.onlinewebfonts.com/t/163635551b4bc4662ba1dea6bd5b9386.eot");
  src: url("https://db.onlinewebfonts.com/t/163635551b4bc4662ba1dea6bd5b9386.eot?#iefix")
      format("embedded-opentype"),
    url("https://db.onlinewebfonts.com/t/163635551b4bc4662ba1dea6bd5b9386.woff2")
      format("woff2"),
    url("https://db.onlinewebfonts.com/t/163635551b4bc4662ba1dea6bd5b9386.woff")
      format("woff"),
    url("https://db.onlinewebfonts.com/t/163635551b4bc4662ba1dea6bd5b9386.ttf")
      format("truetype"),
    url("https://db.onlinewebfonts.com/t/163635551b4bc4662ba1dea6bd5b9386.svg#Founders Grotesk Text Medium")
      format("svg");
} */

/* Icomoon */
@font-face {
  font-family: "icomoon";
  src: url("../fonts/icomoon.eot?s1z09a");
  src: url("../fonts/icomoon.eot?s1z09a#iefix") format("embedded-opentype"),
    url("../fonts/icomoon.ttf?s1z09a") format("truetype"),
    url("../fonts/icomoon.woff?s1z09a") format("woff"),
    url("../fonts/icomoon.svg?s1z09a#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-uniE900:before {
  content: "\e900";
}

.icon-g1476:before {
  content: "\e901";
}

.icon-Group-23:before {
  content: "\e902";
}

.icon-Group-29 .path1:before {
  content: "\e903";
  color: rgb(248, 47, 69);
}

.icon-Group-29 .path2:before {
  content: "\e904";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-Group-31 .path1:before {
  content: "\e905";
  color: rgb(255, 255, 255);
}

.icon-Group-31 .path2:before {
  content: "\e906";
  margin-left: -1em;
  color: rgb(248, 47, 69);
}

.icon-Group-3386 .path1:before {
  content: "\e907";
  color: rgb(248, 47, 69);
}

.icon-Group-3386 .path2:before {
  content: "\e908";
  margin-left: -1.26953125em;
  color: rgb(25, 63, 118);
}

.icon-Vector-1:before {
  content: "\e909";
  color: #fff;
}

.icon-Vector-2:before {
  content: "\e90a";
  color: #f82f45;
}

.icon-Vector-3:before {
  content: "\e90b";
  color: #f82f45;
}

.icon-Vector-4:before {
  content: "\e90c";
  color: #193f76;
}

.icon-Vector-5:before {
  content: "\e90d";
  color: #f82f45;
}

.icon-Vector-6:before {
  content: "\e90e";
  color: #f82f45;
}

.icon-Vector-7:before {
  content: "\e90f";
  color: #f82f45;
}

.icon-Vector-8:before {
  content: "\e910";
  color: #f82f45;
}

.icon-Vector-9:before {
  content: "\e911";
  color: #f82f45;
}

.dealer_right .icon-Vector-5:before {
  content: "\e90d";
  color: #193f76;
}

.dealer_right .icon-Vector-6:before {
  content: "\e90e";
  color: #193f76;
}

.dealer_right .icon-Vector-7:before {
  content: "\e90f";
  color: #193f76;
}

.dealer_right .icon-Vector-8:before {
  content: "\e910";
  color: #193f76;
}

.dealer_right .icon-Vector-9:before {
  content: "\e911";
  color: #193f76;
}

.icon-Vector-10:before {
  content: "\e912";
  color: #7a7a7a;
}

.icon-Vector-11:before {
  content: "\e913";
  color: #7a7a7a;
}

.icon-Vector-12:before {
  content: "\e914";
  color: #7a7a7a;
}

.icon-Vector-13:before {
  content: "\e915";
  color: #7a7a7a;
}

.icon-Vector-14:before {
  content: "\e916";
  color: #fff;
}

.icon-Vector-15:before {
  content: "\e917";
}

.icon-Vector-16:before {
  content: "\e918";
  color: #8f8f8f;
}

.icon-Vector-17:before {
  content: "\e919";
  color: #8f8f8f;
}

.icon-Vector-18:before {
  content: "\e91a";
}

.icon-Vector-19:before {
  content: "\e91b";
}

.icon-Vector-20:before {
  content: "\e91c";
}

.icon-Vector-21:before {
  content: "\e91d";
  color: #fff;
}

.icon-Vector-22:before {
  content: "\e91e";
  color: #fff;
}


.icon-Vector-23:before {
  content: "\e91f";
  color: #fff;
}

.icon-Vector:before {
  content: "\e920";
  color: #fff;
}

.icon-whatsapp:before {
  content: "\ea93";
  color: #fff;

}

/* Icomoon End */

/* Global */
a {
  text-decoration: none;
}

/* Global End */

/* Menu */
.header_menu ul.slimmenu li a {
  padding: 10px 9px;
  text-decoration: none;
  color: #000;
  background: #fff;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
}

.header_menu ul.slimmenu li a:hover,
.current-menu-item a {
  color: #bf1725 !important;
}

.header_menu ul.slimmenu li {
  border: 0;
  font-family: "Montserrat", sans-serif;
  color: #000;
  font-weight: 500;
}

.header_section .row .header_col {
  display: grid;
  align-items: center;
}

.has-submenu:hover ul {
  display: block;
}

.header_section .row div:nth-child(2) {
  justify-content: end;
}

.header_menu ul.slimmenu li .sub-toggle {
  background: transparent;
  width: 20px;
  height: auto;
  top: 44%;
  transform: translateY(-50%);
}

.header_menu ul.slimmenu li ul {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  width: 150px;
}

.header_menu ul.slimmenu li ul li {
  float: none;
  width: 100%;
}

.header_menu ul.slimmenu li>ul {
  padding-left: 0;
  text-align: left;
}

.header_menu ul.slimmenu li .sub-toggle i {
  display: none;
}

.header_menu ul.slimmenu li .sub-toggle::before {
  width: 7px;
  height: 7px;
  background: #fff;
  border-left: 1.5px solid #1e1e1e;
  border-bottom: 1.5px solid #1e1e1e;
  transform: rotate(-45deg);
  margin-left: 10px;
  position: relative;
}

/* Menu End */

.header_section {
  padding: 10px 0;
  z-index: 99999;
  position: relative;
}

.header_section.fixed {
  z-index: 9999;
  position: fixed;
  background: #fff;
  width: 100%;
  -webkit-animation-name: animationFade;
  -o-animation-name: animationFade;
  animation-name: animationFade;
  -webkit-animation-duration: 1s;
  -o-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  -o-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-box-shadow: 0px 2px 30px rgba(0, 0, 0, 0.19);
  box-shadow: 0px 2px 30px rgba(0, 0, 0, 0.19);
}

.header_contact {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.header_contact .header_phone {
  margin-right: 10px;
  /* font-weight: 600; */
}

/* .header_contact .email_text {
  font-weight: 600;
} */
.header_contact>div {
  justify-content: space-between;
}

.header_section .row div:nth-child(3) {
  justify-content: end;
}

.header_email span.icon {
  background: #193f76;
  width: 45px;
  height: 45px;
  border-radius: 100%;
  box-shadow: 5px 8px 10px rgba(0, 0, 0, 0.34);
  margin-right: 5px;
}

.header_phone span.icon {
  background: #f82f45;
  width: 45px;
  height: 45px;
  border-radius: 100%;
  box-shadow: 5px 8px 10px rgba(0, 0, 0, 0.34);
  margin-right: 7px;
}

.header_email .email_text,
.header_phone .phone_text {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 26px;
  color: #000000;
}




.header_email a:hover .email_text,
.header_phone a:hover .phone_text {
  color: #bf1725;
}

.header_email {
  margin-right: 20px;
}

.header_email span.icon:before,
.header_phone span.icon:before {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 50px;
}

.header_section .row .header_col1 {
  width: 12%;
}

.header_section .row .header_col2 {
  width: 62%;
}

.header_section .row .header_col3 {
  width: 26%;
}

.banner_form {
  align-items: center;
  margin-top: 50px;
}

.banner_content {
  text-align: center;
  position: absolute;
  top: 5%;
  right: 7%;
  width: 28%;
  z-index: 9;
  background: #fff;
  padding: 30px 30px;
  border-radius: 15px;
}

.banner_form .mark {
  background-color: transparent;
}

.banner_section {
  position: relative;
}

.banner_content h1 {
  font-family: "Jost";
  font-style: normal;
  font-weight: 400;
  font-size: 74px;
  line-height: 50px;
  letter-spacing: -0.08em;
  text-transform: uppercase;
  color: #193f76;
}

.banner_form .form_input.submit_input {
  width: 35%;
  float: right;
}

.banner_form .form_input.submit_input .submittext i {
  margin-left: 10px;
  position: relative;
  top: 1px;
}

.banner_form .form_input {
  width: 100%;
  position: relative;
  margin-right: 10px;
}

.banner_form .form_input input,
.banner_form .form_input select {
  background: #ffffff;
  border: 1px solid #dbdbdb;
  box-shadow: 20px 45px 64px rgba(0, 0, 0, 0.13);
  border-radius: 68px;
  padding: 10px 10px 10px 40px;
  width: 100%;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;

  color: #3f3f3f;
  outline: 0;
  margin-bottom: 12px !important;
}

.bnrslct {
  position: relative;
  width: 100%;
  margin-bottom: 12px;
}

.bnrslct select {
  width: 100%;
  border-radius: 68px;
  border: 1px solid #dbdbdb;
  height: 40px;
  color: #989898;
  font-family: "Montserrat", sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  padding: 0 30px 0 40px;
  background: #fff;
  background: #fff url(../images/drp.png) no-repeat right 15px top 15px;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
}

.bnrslct span {
  position: absolute;
  top: 50%;
  left: 13px;
  transform: translateY(-50%);
  color: #7a7a7a;
}

.dsplygap {
  gap: 10px;
}

.banner_form .form_input .form_submit {
  box-shadow: none;
  background: #049648;
  border-radius: 30px;
  color: #fff;
  padding: 15px 10px;
  text-align: left;
  padding-left: 20px;
  transition: all 0.3s ease-in;
}

.banner_form .form_input .form_submit:hover {
  background: #193f76;
  transition: all 0.3s ease-in;
}

.sellcarbtn .form_input .form_submit {
  padding: 5px 30px;
}

.banner_form .form_input span {
  position: absolute;
  top: 40%;
  left: 13px;
  transform: translateY(-50%);
  color: #7a7a7a;
}

.banner_form .form_input .icon-Vector-14 {
  top: inherit;
  position: static;
}

.banner_form .sellcarbtn .icon-Vector-14 {
  top: inherit;
  position: static;
  margin-top: 2px;
}

.submit_input {
  margin-right: 0 !important;
}

.submit_input span {
  left: auto !important;
  right: 15px;
}

.banner_form .form_input select {
  padding: 0 20px 0 40px;
  margin-bottom: 20px;
  color: #7a7a7a;
  height: 44px;
}

.banner_form .form_input input {
  color: #7a7a7a !important;
}

.sell-bye {
  width: auto;
  border-radius: 50px;
  margin: auto;
  border: none;
  background-color: #f82f45;
  box-shadow: 0px 26px 30px 0px rgba(162, 191, 233, 0.17);
  position: relative;
  display: inline-flex;
  justify-content: space-between;
  /* max-width: 342px; */
}

.sell-bye .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  /* border: 1px solid #F82F45 !important; */
  /* background-color: #F82F45 !important; */
  color: #fff !important;
  border-radius: 50px;
  position: relative;
}

.banner_section .sell-bye .nav-link.active {
  border: 1px solid #f82f45 !important;
  background-color: #f82f45 !important;
  color: #fff !important;
  border-radius: 50px !important;
  position: relative;
}

.sell-bye .nav-link:focus,
.sell-bye .nav-tabs .nav-link:hover {
  border: 1px solid #f82f45 !important;
}

.banner_content .nav-tabs .nav-link {
  text-decoration: none !important;
}

.banner_content .nav-tabs .nav-link:hover {
  text-decoration: none !important;
}

.sell-bye .nav-link:focus,
.nav-tabs .nav-link:hover {
  border: 1px solid #f82f45;
}

.sell-bye .nav-link {
  font-size: 22px;
  border: 1px solid #1c4075 !important;
  background-color: #fff;
  border-radius: 50px !important;
  padding: 16px 15px 15px;
  color: #1c4075;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
}

.sell-bye .nav-link a {
  text-decoration: none;
}

.sell-bye .nav-item .nav-link.active::before {
  content: "";
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #f82f45;
  position: absolute;
  left: 50%;
  bottom: -20%;
}

.sell-bye .nav-item {
  /* margin-right: auto; */
}

/* How Works Section */

.work_tabs {
  justify-content: center;
  border: 0;
  margin-top: 20px;
}

.how_works_section .nav-tabs {
  border-bottom: 0px !important;
  max-width: 403px;
  margin: 30px auto;
  padding-left: 30px;
}

.work_heading h2 {
  font-family: "montserratbold";
  font-style: normal;
  font-weight: 700;
  font-size: 59px;
  line-height: 85px;
  letter-spacing: -0.08em;
  text-align: center;
  color: #000000;
}

.work_tabs a {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 30px;
  text-align: center;
  color: #7e7e7e;
  background: #dbdbdb;
  border: 0 !important;
}

.work_tabs a.active,
.work_tabs a:hover {
  color: #fff !important;
}

.work_content .work_text {
  font-family: "montserratsemibold";
  font-style: normal;
  /* font-weight: 600; */
  font-size: 17px;
  line-height: 20px;
  color: #000000;
}

.work_content .work_num {
  font-family: "montserratbold";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.08em;
  color: #000000;
  background: #ffffff;
  box-shadow: 4px 17px 35px rgba(0, 0, 0, 0.29);
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  border-radius: 100%;
  position: absolute;
  right: 50px;
}

.work_content .icon {
  width: 100px;
  height: 100px;
  background: #d3dff1f2;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 40px;
  margin: 0 auto 10px;
  border: 1px solid #193f76;
}

.work_points_left,
.work_points_right {
  text-align: center;
  width: 220px;
  margin: 0 auto;
  position: relative;
  transition: all 0.3s ease-in;
}

.work_content .icon:hover {
  /* background: #f82f45; */
  transition: all 0.3s ease-in;
}

.work_content {
  margin-top: 100px;
}

.work_points_left div,
.work_points_right div {
  position: relative;
}

.book_home {
  right: -300px;
  top: -180px;
}

.enter_details {
  top: 160px;
  left: 70px;
}

.get_offers {
  top: -75px;
  left: -170px;
}

.hassle_free {
  bottom: -100px;
  left: -160px;
}

/* How Works Ends */

.addBanner .car2back .cartext {
  text-align: left;
}

/* Otobix Section */
.otobix_container h2 {
  font-family: "Jost";
  font-style: normal;
  font-weight: 500;
  font-size: 59px;
  line-height: 85px;
  letter-spacing: -0.08em;
  text-transform: uppercase;
  color: #000000;
}

.otobix_container .red {
  color: #f82f45;
}

.otobix_container .blue {
  color: #193f76;
}

.otobix_points ul li {
  padding: 20px 0;
  display: flex;
  align-items: center;
  padding-right: 30px;
  width: 50%;
}

.otobix_points ul {
  display: flex;
  padding-left: 10px;
  justify-content: space-between;
  margin-bottom: 0;
}

.otobix_points ul li .otobix_text {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  color: #333340;
  margin-left: 10px;
}

.otobix_points ul:first-child {
  border-bottom: 1px solid #e2e2e2;
}

.otobix_points ul li .icon {
  font-size: 35px;
}

.otobix_points {
  margin-right: 30px;
}

.otobix_col {
  padding: 0;
}

.otobix_section .row .otobix_col:first-child {
  background: #f8f8f8;
  display: flex;
  align-items: center;
  position: relative;
}

.otobix_images {
  position: relative;
  top: 20px;
}

.otobix_images img:last-child {
  position: absolute;
  top: -2px;
  right: -90px;
  width: 57%;
}

.otobix_section .row .otobix_col:first-child:before {
  content: "";
  position: absolute;
  background: #f8f8f8;
  width: 100%;
  height: 100%;
  left: -170px;
  z-index: -1;
}

.otobix_images img:first-child {
  clip-path: polygon(0 0%, 87% 1%, 100% 100%, 13% 100%);
}

.otobix_section .row .otobix_col:first-child::after {
  content: "";
  position: absolute;
  background: #f8f8f8;
  width: 100%;
  height: 90%;
  right: -100px;
  z-index: -1;
  margin-top: 43px;
}

.otobix_points ul:last-child li:last-child {
  margin-right: 15px;
}

/* Otobix Ends */

/* About Section */
.about_section {
  padding: 50px 0;
  position: relative;
}

.about_container h2 {
  font-family: "montserratbold";
  font-style: normal;
  font-weight: 700;
  font-size: 59px;
  line-height: 85px;
  letter-spacing: -0.04em;
  text-transform: capitalize;
  color: #000000;
}

.about_container p:nth-child(2) {
  font-family: "Montserrat", sans-serif;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 19px !important;
  line-height: 26px !important;
  color: #171e1c !important;
}

.about_container p:nth-child(3) {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 30px;
  color: #273431;
}

.otobix_button {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 30px;
  letter-spacing: -0.05em;
  color: #ffffff;
  background: #f82f45;
  border-radius: 30px;
  padding: 7px 60px;
  display: inline-block;
  transition: all 0.3s ease-in;
  margin-top: 5px;
}

.otobix_button:hover {
  background: #193f76;
  transition: all 0.3s ease-in;
}

/* About Ends */

/* Testimonial Section */
.testimonial_section {
  padding: 50px 0;
  background: #f8f8f8;
}

.testimonial_container h2 {
  font-family: "montserratbold";
  font-style: normal;
  font-weight: 700;
  font-size: 59px;
  line-height: 85px;
  letter-spacing: -0.04em;
  text-transform: capitalize;
  color: #000000;
}

.testimonial_container p {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 30px;
  letter-spacing: -0.05em;
  color: #273431;
}

.testimonial_container {
  width: 80%;
}

.test_icon {
  text-align: center;
}

.test_col {
  display: flex;
  align-items: center;
}

.test_colm {
  border-right: 1px solid #e4e4e4;
}

.text_testimonial label,
.image_testimonial label {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 24px;
  text-transform: uppercase;
  color: #009f43;
}

.text_testimonial p,
.image_testimonial p {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 24px;
  color: #171e1c;
}

.text_testimonial span.icon,
.test_desc_icon span.icon {
  width: 50px;
  height: 50px;
  background: #fff;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000;
}

.client_details span {
  display: block;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  color: #171e1c;
}

.client_details small {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 15px;
  color: #a4b8b3;
}

.client_details {
  margin-left: 10px;
}

.image_testimonial .test_image {
  width: 45%;
  margin-right: 20px;
  background: #ededed;
}

.image_testimonial .test_desc {
  width: 100%;
}

.test_image img {
  border-radius: 5px;
}

.image_testimonial .client_details {
  margin-left: 0px;
}

.test_desc_icon {
  margin-right: 85px;
  margin-bottom: 15px;
}

.testimonial_slider .row .col-lg-6:last-child,
.testimonial_slider .row .col-lg-6:nth-last-child(2) {
  padding-top: 50px;
}

.testimonial_slider .owl-nav {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 495px;
  margin-top: 50px;
}

.testimonial_slider .owl-nav button {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  border: 2px solid #b6b6b6 !important;
}

/* .testimonial_slider .owl-nav button:first-child {
  margin-right: 10px;
} */

.testimonial_slider .owl-nav button:hover {
  background: #fff !important;
  border: 2px solid #193f76 !important;
  color: #193f76 !important;
}

.testimonial_slider .owl-nav button:hover span:before {
  color: #193f76;
}

.testimonial_slider .owl-nav span {
  display: none;
}

.testimonial_slider .owl-prev::before {
  font-family: "icomoon" !important;
  font-size: 13px;
  content: "\e919";
}

.testimonial_slider .owl-next::before {
  font-family: "icomoon" !important;
  font-size: 13px;
  content: "\e918";
}

.testicon_none {
  height: 50px;
}

.image_testimonial {
  padding-left: 20px;
}

.text_testimonial {
  padding: 0 10px 0 30px;
}

.test_colm .test_desc_icon {
  padding: 0 30px;
}

/* Testimonial Ends */

/* FAQ Section */
.faq_section {
  padding: 50px 0 150px;
  position: relative;
}

.faq_heading {
  text-align: center;
}

.faq_button {
  text-align: center;
  margin-top: 50px;
}

.faq_heading h2 {
  font-family: "montserratbold";
  font-style: normal;
  font-weight: 700;
  font-size: 59px;
  line-height: 85px;
  letter-spacing: -0.04em;
  text-transform: capitalize;
  color: #000000;
  margin-bottom: 30px;
}

.accordion {
  margin-left: 360px;
  width: 70%;
}

.accordion-button {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  border-top-left-radius: 6px !important;
  border-top-right-radius: 6px !important;
  box-shadow: none !important;
  color: #000000 !important;
  display: inline-block;
}

.accordion-item {
  background: transparent;
  border-radius: 6px;
  margin-bottom: 10px;
  border: 0;
}

.accordion-body {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
  padding: 20px 35px;
}

.accordion-body ul li {
  margin-bottom: 30px;
  list-style: none;
  position: relative;
  padding: 0 30px;
}

.accordion-body ul {
  column-count: 2;
  margin-bottom: 0;
  padding-left: 0;
}

.accordion-body ul li:before {
  content: "";
  position: absolute;
  background: url("../images/faq_check.png") no-repeat left center;
  width: 20px;
  height: 20px;
  left: 0;
}

.accordion-button {
  background: transparent;
  border-bottom: 1px solid #ddddec;
}

.accordion-button:not(.collapsed) {
  background: transparent;
  border: none;
}

.accordion-button:focus {
  box-shadow: none;
}

.faq_section:before {
  content: "";
  position: absolute;
  width: 35%;
  height: 1103px;
  background: url("../images/accleft.png") no-repeat left center;
  left: 0;
  background-size: contain;
  z-index: -1;
  top: 73%;
  transform: translateY(-55%);
}

.faq_section:after {
  content: "";
  position: absolute;
  width: 20%;
  height: 550px;
  background: url("../images/accriImg.png") no-repeat right center;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  background-size: contain;
  z-index: 1;
}

.accordion-button::after {
  content: "" !important;
  position: absolute !important;
  width: 100% !important;
  height: 100% !important;
  right: 40px !important;
  background: url("../images/faq_up.png") no-repeat left center !important;
  transition: all 0.3s ease-in !important;
  top: 0;
}

.accordion-button:not(.collapsed)::after {
  transform: inherit !important;
  transition: all 0.3s ease-in !important;
  background: url("../images/faq_down.png") no-repeat left center !important;
}

/* FAQ Ends */

.contactus.page_section {
  padding-bottom: 180px;
}

.terms_section.page_section {
  margin-bottom: 150px;
}

.faq_main_wrap .faq_section {
  margin-bottom: 130px;
}

.faq_inner_page {
  padding: 10px 0px 10px !important;
}

/* Footer */
.footer_section {
  padding: 80px 0 28px;
  background: url(../images/fback.webp) no-repeat #171616;
  position: relative;
  background-size: cover;
}

.footer_section:before {
  content: "";
  position: absolute;
  height: 100%;
  background-size: cover;
  mix-blend-mode: difference;
  opacity: 0.1;
  top: 0;
  z-index: -1;
}

.footer_section .row h5 {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 18px;
  letter-spacing: -0.04em;
  color: #686874;
  margin-bottom: 30px;
}

.footer_menu ul {
  list-style-type: none;
  flex-wrap: wrap;
}

.footer_menu ul li {
  color: #373636;
  margin-bottom: 31px;
  list-style-type: none;
  position: relative;
}

.footer_menu ul li a {
  margin-left: 25px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 23px;
  text-transform: capitalize;
  color: #000;
  list-style-type: none;
}

.footer_menu ul li a:hover {
  color: #f82f45;
}

.footer_menu ul li>.fsub-menu {
  display: none;
  padding: 0;
}

.footer_menu ul li>.fsub-menu {
  position: absolute;
  left: 0;
  bottom: 105%;
  z-index: 999;
  display: none;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  width: 150px;
  background: #fff;
}

.footer_menu ul li ul li {
  margin-bottom: 12px;
}

.footer_menu ul li ul li a {
  margin: 0;
  padding: 0 10px;
  font-size: 14px;
  display: block;
}

.footer_menu ul li ul li a:hover {
  color: #f82f45;
}

.footer_menu ul li:hover ul {
  display: block;
}

/* Footer Ends */

.footer_contact .faddress {
  padding: 0;
  border-radius: 59px 0px 0px 59px;
  background: #fff !important;
  box-shadow: 0px 45px 60px 0px rgba(186, 215, 224, 0.51);
  padding: 30px 50px 30px 50px;
  margin-bottom: 40px;
  gap: 20px;
  width: 100%;
  justify-content: space-between;
}

.footer_contact ul li {
  display: inline-block;
  vertical-align: top;
  position: relative;
  padding-left: 70px;
  min-height: 60px;
  line-height: 60px;
  margin-bottom: 0px;
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  color: #000;
}

.footer_contact ul li span.icon {
  width: 60px;
  height: 60px;
  display: block;
  background: #e92c40;
  border-radius: 50%;
  position: absolute;
  text-align: center;
  line-height: 60px;
  top: 0;
  left: 0;
  color: #fff;
}

.fsocial {
  background: #193f76 !important;
  border-radius: 0px 59px 59px 0px;
  box-shadow: 0px 45px 60px 0px rgba(186, 215, 224, 0.51);
  padding: 30px 20px 30px 50px;
  margin-bottom: 40px;
  max-width: 270px;
  width: 100%;
}

.footer_contact {
  margin: 0 auto;
  margin-top: 50px;
  max-width: 1278px;
  width: 100%;
}

.flogo {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  width: auto;
  max-width: 100%;
}

.fcar {
  position: absolute;
  top: -88px;
  right: 0;
  transform: translateY(-50%);
  max-width: 100%;
}

.footer_contact ul li.ft_address {
  /* width: 380px; */
  max-width: 100%;
  line-height: 25px;
  display: flex;
  align-items: center;
}

.footer_contact ul li.ft_phone {
  /* width: 250px; */
  max-width: 100%;
  line-height: 25px;
  display: flex;
  align-items: center;
}

.footer_contact ul li.ft_email {
  /* width: 390px; */
  max-width: 100%;
}

.footer_contact ul li a {
  color: #000;
  text-decoration: none;
}

.footer_contact ul li a:hover {
  color: #f82f45;
  text-decoration: none;
}

.footer_contact ul li.social_media {
  padding-left: 0;
}

.footer_contact ul li.social_media a {
  width: 44px;
  height: 44px;
  display: inline-block;
  border: 2px solid #a0a0a0;
  text-align: center;
  line-height: 44px;
  margin-right: 10px;
  border-radius: 50%;
  font-size: 14px;
}

.footer_contact ul li.social_media a:hover {
  background: #fff;
  color: #000;
  border-color: #fff;
}

.footer_contact ul li.social_media a:hover span:before {
  color: #f82f45;
}

.ft_bottom_section {
  text-align: center;
}

.ft_bottom_section p {
  margin: 0;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: -1px;
  color: #000;
}

.work_tabs li a.nav-link {
  background: url(../images/tab_button_one.png) no-repeat;
  width: 201px;
  min-height: 70px;
  color: #1c4075;
}

.work_tabs li.nav-item {
  margin-left: -46px;
}

.work_tabs li a.nav-link.active,
.work_tabs li a.nav-link:hover {
  background: url(../images/tab_button_one_hover.png) no-repeat;
  width: 201px;
  z-index: 999;
  position: relative;
}

.work_tabs li a.dealer-tab.nav-link.active {
  background: url(../images/delar-tab-btn.png) no-repeat !important;
}

.work_content .icon.dealer-icon:hover {
  /* background: #193f76; */
  transition: all 0.3s ease-in;
}

.work_content .icon.dealer-icon {
  background: #f82f451f;
  border-radius: 106px;
  border: 1px solid #ff7685;
}

.otobix_section {
  overflow: hidden;
}

.car-motion {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
}

.car-motion .container {
  position: relative;
}

.map-wrapper {
  text-align: right;
  position: relative;
  width: 64%;
  top: -40px;
}

.map-wrapper::before {
  position: absolute;
  left: 36%;
  content: "";
  top: 70%;
  width: 60%;
  height: 1px;
  /* background: #b3b3c1; */
  border: 1.2px dashed;
}

.cararea {
  background: #b3b3c1;
  height: 2px;
  top: 70%;
  position: absolute;
  left: 36%;
}

.map-wrapper img {
  position: relative;
  z-index: 2;
  width: auto;
  height: auto;
  max-width: 108px;
}

.car-wrapper {
  position: absolute;
  top: -70%;
  left: -6%;
}

.car-wrapper img {
  width: auto;
  height: auto;
  max-width: 100%;
}

.banner_section>img {
  width: 100%;
}

.desktop-banner {
  display: block;
}

.mobile-banner {
  display: none;
}

/* .map-wrapper:after {
  position: absolute;
  left: 64%;
  content: "";
  top: 56.5%;
  width: 13px;
  height: 13px;
  border-radius: 100%;
  background: #e7302a;
  border: 2px solid #fff;
} */
.kolarea {
  position: absolute;
  left: 36%;
  content: "";
  top: 65.5%;
  width: 13px;
  height: 13px;
  border-radius: 100%;
  background: #fff;
  border: 2px solid #fff;
}

.siliguriarea {
  position: absolute;
  left: 59%;
  content: "";
  top: 65.5%;
  width: 13px;
  height: 13px;
  border-radius: 100%;
  background: #fff;
  border: 2px solid #e7302a;
}

.bhuvarea {
  position: absolute;
  left: 75%;
  content: "";
  top: 65.5%;
  width: 13px;
  height: 13px;
  border-radius: 100%;
  background: #e7302a;
  border: 2px solid #fff;
}

.stop1 {
  background: rgba(248, 47, 69, 0.7);
  padding: 10px 25px;
  color: #fff;
  border-radius: 30px;
  position: absolute;
  left: 30%;
  top: -19px;
}

.stop1::after {
  content: "";
  width: 1px;
  border-right: 1.2px dotted #ec6778;
  height: 26px;
  position: absolute;
  top: 100%;
  left: 45%;
}

.stop2 {
  background: rgba(248, 47, 69, 0.7);
  padding: 10px 25px;
  color: #fff;
  border-radius: 30px;
  position: absolute;
  left: 51%;
  top: -19px;
}

.stop2::after {
  content: "";
  width: 1px;
  border-right: 1.2px dotted #ec6778;
  height: 26px;
  position: absolute;
  top: 100%;
  left: 52.5%;
}

.stop3 {
  background: rgba(248, 47, 69, 0.7);
  padding: 10px 25px;
  color: #fff;
  border-radius: 30px;
  position: absolute;
  right: 15%;
  top: -19px;
}

.stop3::after {
  content: "";
  width: 1px;
  border-right: 1.2px dotted #ec6778;
  height: 26px;
  position: absolute;
  /* top: 100%; */
  top: 87%;

  left: 50%;
}

/******************new 21/9/2023********************************/
.Why-OtoBix {
  padding: 100px 0px 0px 0px;
}

.Why-OtoBix .work_heading h1 {
  color: #000;
  font-family: "montserratbold";
  font-size: 59px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -2.36px;
  text-transform: capitalize;
  margin-bottom: 100px;
  text-align: center;
}

.Why-OtoBix .work_heading h2 {
  color: #000;
  font-family: "montserratbold";
  font-size: 59px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -2.36px;
  text-transform: capitalize;
  margin-bottom: 100px;
}

.redd {
  color: #f82f45;
}

.bluee {
  color: #193f76;
}

.whyOtoBix {
  border-radius: 15px;
  padding: 20px;
  max-width: 395px;
  min-height: 200px;
  margin-bottom: 90px;
  border-style: solid;
  border: 1px solid transparent;
  background: linear-gradient(white, white) padding-box,
    linear-gradient(to right bottom, #193f76, #f2f4f7) border-box;
}

.whyOtoBix img {
  margin-top: -80px;
  max-width: 173px;
}

.whyOtoBix p {
  color: #333340;
  text-align: center;
  font-family: "montserratmedium";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: auto;
  width: 75%;
}

.custommRow {
  padding: 20px 70px;
}

.BrandLogo {
  background: #f2fcff;
  padding: 50px 0px 80px 0px;
  position: relative;
}

.BrandLogo::after {
  content: "";
  position: absolute;
  width: 18%;
  height: 550px;
  background-image: url("../images/brand-right.png");
  right: 0;
  top: 110%;
  transform: translateY(-50%);
  background-size: cover;
  z-index: 1;
}

.BrandLogo .heading {
  display: flex;
  justify-content: flex-start;
}

.BrandLogo .heading h2 {
  color: #000;
  font-family: "montserratbold";
  font-size: 59px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 50px;
  text-align: left;
  text-align: center;
  letter-spacing: -0.04em;
}

.brand .item img {
  width: 215px !important;
  height: 215px;
  border: 1px solid #ddd;
  border-radius: 100px;
  object-fit: none;
  object-fit: scale-down;
  background: #fff;
}

.brand .owl-nav {
  display: block !important;
  position: absolute;
  right: 0;
  top: -75%;
  text-align: right !important;
}

.brand .owl-prev,
.brand .owl-next {
  width: 45px;
  height: 45px;
  border: 2px solid #b6b6b6 !important;
  border-radius: 50px;
  margin-right: 10px;
}

.brand .owl-prev span,
.brand .owl-next span {
  font-size: 13px;
}

.brand .owl-next:hover ::before,
.brand .owl-prev:hover ::before {
  color: #193f76 !important;
}

.brand .owl-next:hover,
.brand .owl-prev:hover {
  background: #fff;
  border: 2px solid #193f76 !important;
  transition: all 0.5s;
}

.contactBtn {
  background: #1c4075;
  color: #fff;
  padding: 15px 40px;
  border-radius: 50px;
  position: relative;
  z-index: 1;
  font-family: "Montserrat", sans-serif;
}

.contactBtn:hover {
  background-color: #e02b3f;
  border: 2px solid #e02b3f;
  color: #fff;
}

.how_works_section {
  padding: 100px 0px;
}

.mid {
  position: relative;
}

.work_image.dealers_img::before {
  position: absolute;
  content: "";
  background: url(../images/line.png) no-repeat 0 0;
  width: 100%;
  height: 570px;
  top: -15%;
  left: -20%;
}

/*.about_image::before{
  position: absolute;
  content: "";
  background: url(../images/bluecar.png) no-repeat 0 0;
  right: 0px;
    width: 680px;
    height: 400px;
    
    bottom: 0;
}*/

.about_container {
  margin: 0px 0 0 333px;
  text-align: left;
}

.about_container .aboutboldtext {
  /* font-weight: 600 !important; */
}

.about_image {
  position: relative;
}

.about_image img {
  position: absolute;
  right: 0;
  top: -130px;
  float: right;
  width: auto;
  height: auto;
  max-width: 100%;
}

.team_content {
  margin-bottom: 100px;
}

.work_image.dealers_img img {
  transform: rotate(330deg);
  margin-left: 0;
  margin-top: 60px;
  width: auto;
  height: auto;
}

.work_image.sellers_img img {
  transform: rotate(330deg);
  margin-left: 0;
  margin-top: 60px;
}

.carDisplay {
  position: relative;
  height: 100%;
}

.displayText {
  position: relative;
  top: 15px;
}

.displayText h4 {
  color: #e02b3f;
  text-align: center;
  font-family: "Montserrat", sans-serif;
  font-size: 48px;
  font-style: normal;
  font-weight: 800;
  line-height: 49px;
  letter-spacing: -1.92px;
}

.displayText .headingtxt {
  color: #e02b3f;
  text-align: center;
  font-family: "Montserrat", sans-serif;
  font-size: 48px;
  font-style: normal;
  font-weight: 800;
  line-height: 49px;
  letter-spacing: -1.92px;
}

.displayText p {
  color: #273431;
  font-family: "Montserrat", sans-serif;
  font-size: 21px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: -1.05px;
}

.click {
  background: #1c4075;
  color: #fff;
  padding: 15px 60px;
  border-radius: 50px;
  border: 2px solid #1c4075;
  display: inline-block;
  font-family: "Montserrat", sans-serif;
}

.click:hover {
  background-color: #e02b3f;
  border: 2px solid #e02b3f;
  color: #fff;
  transition: all 0.5s ease-in-out;
}

.displayimg {
  position: absolute;
  bottom: 0;
  text-align: center;
}

.displayimg img {
  max-width: 98%;
  width: auto;
  height: auto;
}

.cartext h6 {
  width: 50%;
  color: #193f76;
  font-family: "Montserrat", sans-serif;
  font-size: 48px;
  font-style: normal;
  font-weight: 800;
  line-height: 49px;
  letter-spacing: -1.92px;
  margin-bottom: 30px;
}

.cartext .headingtxt {
  width: 50%;
  color: #193f76;
  font-family: "Montserrat", sans-serif;
  font-size: 48px;
  font-style: normal;
  font-weight: 800;
  line-height: 49px;
  letter-spacing: -1.92px;
  margin-bottom: 30px;
}

.cartext {
  padding: 80px 30px;
}

.here {
  background: #e02b3f;
  color: #fff;
  padding: 15px 60px;
  border-radius: 50px;
  border: 2px solid #e02b3f;
  font-family: "Montserrat", sans-serif;
}

.here:hover {
  background-color: #1c4075;
  border: 2px solid #1c4075;
  color: #fff;
  transition: all 0.5s ease-in-out;
}

.banner_form .form_input.submit_input {
  width: 100%;
  float: right;
}

.banner_form .submitt {
  position: relative;
  margin: auto;
}

.banner_form .form_input .form_submit {
  box-shadow: none;
  background: #049648;
  border-radius: 30px;
  color: #fff !important;
  padding: 0px 15px;
  text-align: center;
  padding-left: 20px;
  transition: all 0.3s ease-in;
  cursor: pointer;
  margin: 0 auto;
  height: 48px;
  border: 0px;
  display: flex;
  align-items: center;
  font-weight: 500;
  justify-content: center;
  font-family: "Montserrat", sans-serif;
}

.banner_form .makemodel {
  width: 100% !important;
}

.loar-against-from {
  width: 100%;
  padding: 80px 0 265px;
  position: relative;
  background: #f2fcff;
  padding-bottom: 300px;
}

.loar-against-from .submitbtn {
  z-index: 999;
}

.loan-from {
  max-width: 790px;
  width: 100%;
  margin: 0 auto;
}

.loan-from h2 {
  color: #1c3f75;
  font-family: "montserratbold";
  font-size: 59px;
  font-style: normal;
  font-weight: 700;
  line-height: 60px;
  letter-spacing: -3.54px;
  position: relative;
  margin-bottom: 30px;
  text-align: center;
  padding-top: 30px;
}

.loan-from h2::before {
  position: absolute;
  content: "";
  width: 110px;
  height: 6px;
  background: #f82f45;
  left: 0;
  right: 0;
  top: 0;
  margin: 0 auto;
}

.loan-from .bkfrom input {
  background: #f2fcff;
}

.loan-from .bkfrom select {
  background: #f2fcff url(../images/drp.png) no-repeat right 15px top 20px;
}

.loanpicc {
  position: absolute;
  bottom: 92px;
  left: 0;
  width: 45%;
}

.loanpicc img {
  max-width: 100%;
}

.lonagnstftlgo {
  transform: translate(-50%, -85%);
}

.urgntrequire .aprochpic {
  position: absolute;
  right: 0;
  top: 0;
}

.urgntrequire .textblue {
  color: #193f76;
}

.btompading p {
  max-width: 80%;
}

.btompading h6 {
  display: block;
  color: #273431;

  font-family: "Montserrat", sans-serif;
  font-size: 19px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
}

.btompading {
  padding-bottom: 100px;
  overflow: hidden;
}

.btompading h3 span {
  color: #f82f45;
}

.loking-loan {
  position: relative;
}

.loking-loan img {
  max-width: 100%;
}

.lookdtl {
  position: absolute;
  left: 20px;
  top: 50%;
  transform: translateY(-50%);
}

.lookdtl span {
  display: block;
  margin-bottom: 12px;
}

.lookdtl h5 {
  font-family: "Montserrat", sans-serif;
  font-size: 22px;
  font-weight: 500;
  line-height: 49px;
  letter-spacing: 0.22em;
  color: #fff;
}

.lookdtl h4 {
  color: #e02b3f;
  font-family: "montserratbold";
  font-size: 58px;
  font-style: normal;
  font-weight: 700;
  line-height: 100.2%;
  letter-spacing: -2.36px;
}

.lookdtl h4 em {
  font-style: normal;
  font-weight: 500;
  display: block;
}

.lookdtl p {
  color: #fff;
  font-family: "Montserrat", sans-serif;
  font-size: 21px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: -1.05px;
}

.lookdtl a {
  color: #fff;
  text-align: center;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: -0.7px;
  border-radius: 30px;
  background: #1c4075;
  padding: 10px 30px 9px;
}

.lookdtl a:hover {
  background: #c31d30;
  color: #fff;
}

.wmslookdt1 h5 {
  letter-spacing: 0.1em !important;
}

.wmslookdt1 .clkhre:hover {
  background: #1c4075 !important;
  color: #fff !important;
}

.wmslookinfodt1 .clkhre2 {
  background: #1c4075 !important;
  color: #fff !important;
  border: #1c4075;
}

.wmslookinfodt1 .clkhre2:hover {
  background: #c31d30 !important;
  color: #fff !important;
  border: #c31d30 !important;
}

/* .lokibtn:hover{
  background: #134494 !important;
}
.lokibtn2:hover{
  background: #e02b3f !important;
} */

.lookinfo {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
}

.lookinfo span {
  display: block;
  margin-bottom: 12px;
}

.lookinfo h5 {
  color: #fff;
  font-family: "Montserrat", sans-serif;
  font-size: 37px;
  font-style: normal;
  font-weight: 500;
  line-height: 100.2%;
  letter-spacing: -1.48px;
}

.lookinfo h4 {
  color: #fff;
  font-family: "Montserrat", sans-serif;
  font-size: 58px;
  font-style: normal;
  font-weight: 800;
  line-height: 49px;
  letter-spacing: -2.32px;
  margin-bottom: 25px;
}

.lookinfo h4 em {
  font-style: normal;
  font-weight: 500;
  display: block;
}

.lookinfo p {
  color: #fff;
  font-family: "Montserrat", sans-serif;
  font-size: 26px;
  font-style: normal;
  font-weight: 500;
  line-height: 40px;
  letter-spacing: -1.04px;
  margin: 0;
}

.lookinfo a {
  color: #fff;
  text-align: center;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: -0.7px;
  border-radius: 30px;
  border: 1px solid #fff;
  background: #e02b3f;
  padding: 10px 35px 9px;
}

.lookinfo a:hover {
  background: #1c4075;
  color: #fff;
}

.looking-option .container>.row {
  margin: 0 -45px;
}

.looking-option .container>.row>div {
  padding: 0 45px;
}

.preowner-sec {
  padding: 0 0;
  position: relative;
  /*  overflow: hidden;*/
}

.preowner-sec h3 {
  color: #000;
  font-family: "montserratbold";
  font-size: 59px;
  font-style: normal;
  font-weight: 700;
  line-height: 64px;
  letter-spacing: -2.36px;
}

.preowner-sec p {
  color: #273431;

  font-family: "Montserrat", sans-serif;
  font-size: 19px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
}

.preowner-sec a {
  border-radius: 68px;
  background: #f82f45;
  color: #fff;
  text-align: center;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  padding: 10px 30px;
  display: inline-block;
  margin-top: 10px;
}

.preowner-sec a:hover {
  background: #1c4075;
}

.preowner-sec .container {
  position: relative;
}

.preownrpic {
  position: absolute;
  left: -13%;
  top: -49%;
}

.preownrpic img {
  max-width: 100%;
}

.preownr-detl {
  padding: 290px 0 255px 35px;
  position: relative;
  z-index: 1;
}

.presell-pic img {
  max-width: 100%;
}

.anglepic {
  position: absolute;
  left: 0;
  top: -27%;
  z-index: -1;
}

.anglepic img {
  max-width: 100%;
}

.looking-option {
  position: relative;
  z-index: 2;
}

.finanabut {
  position: relative;
  max-width: 605px;
  width: 100%;
  float: right;
}

.finanabut img {
  width: 100%;
}

.finanabut::before {
  content: "";
  width: 222px;
  height: 302px;
  background: #f82f45;
  position: absolute;
  bottom: -18px;
  left: -18px;
  z-index: -1;
}

.finance-feture-usp {
  background: #f2fcff;
  padding: 60px 0 300px 0;
  margin: 60px 0 0;
}

.inerfeturre-heading {
  text-align: center;
}

.inerfeturre-heading h2 {
  color: #000;

  font-family: "montserratbold";
  font-size: 59px;
  font-style: normal;
  font-weight: 700;
  line-height: 60px;
  letter-spacing: -3.54px;
  position: relative;
  margin-bottom: 30px;
  text-align: center;
  padding-top: 30px;
}

.inerfeturre-heading h2::before {
  position: absolute;
  content: "";
  width: 110px;
  height: 6px;
  background: #f82f45;
  left: 0;
  right: 0;
  top: 0;
  margin: 0 auto;
}

.udpfeture-bx {
  width: 100%;
  display: flex;
  gap: 15px;
  /*  align-items: center;*/
  padding: 20px 0;
}

.uspfutr-icon {
  position: relative;
}

.uspfutr-icon img {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}

.uspfeture-objctt h6 {
  color: #333340;
  font-family: "Montserrat", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.uspfeture-objctt p {
  color: #273431;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
}

.uspmidlpic {
  margin-top: 100px;
}

.uspmidlpic img {
  max-width: 100%;
}

.emicalulator-btn {
  text-align: center;
}

.emicalulator-btn a {
  color: #fff;
  text-align: center;

  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  background: #f82f45;
  padding: 10px 30px;
  border-radius: 30px;
  cursor: pointer;
}

.emicalulator-btn a:hover {
  background: #1c4075;
  color: #fff;
}

.emicalulator-btn span {
  color: #fff;
  text-align: center;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  background: #f82f45;
  padding: 10px 30px;
  border-radius: 30px;
  cursor: pointer;
}

.emicalulator-btn span:hover {
  background: #1c4075;
  color: #fff;
}

.iconusp {
  position: relative;
}

.two-add-show {
  position: relative;
  margin-top: -160px;
  margin-bottom: 100px;
}

.uspadd-box {
  position: relative;
}

.uspadd-box img {
  max-width: 100%;
}

.uspfrst-add>img {
  margin-bottom: 12px;
}

.uspfrst-add {
  position: absolute;
  top: 30px;
  left: 0;
  text-align: center;
  width: 100%;
}

.uspfrst-add h6 {
  font-family: "Montserrat", sans-serif;
  font-size: 22px;
  font-weight: 500;
  line-height: 49px;
  letter-spacing: 0.29em;
  text-align: center;
  color: #fff;
}

.uspfrst-add h5 {
  color: #fff;
  text-align: center;
  font-family: "Montserrat", sans-serif;
  font-size: 48px;
  font-style: normal;
  font-weight: 800;
  line-height: 49px;
  letter-spacing: -1.92px;
}

.uspfrst-add a {
  color: #fff;
  text-align: center;

  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: -0.7px;
  padding: 8px 30px;
  background: #e02b3f;
  border-radius: 30px;
  display: inline-block;
  margin-top: 5px;
}

.uspfrst-add a:hover {
  background: #1c4075;
  color: #fff;
}

.uspfrst-add2 {
  position: absolute;
  top: 40px;
  right: 0;
  width: 100%;
  max-width: 326px;
}

.uspfrst-add2 h5 {
  color: #fff;
  font-family: "Montserrat", sans-serif;
  font-size: 48px;
  font-style: normal;
  font-weight: 800;
  line-height: 49px;
  letter-spacing: -1.92px;
  position: relative;
}

.uspfrst-add2 h5::after {
  max-width: 130px;
  width: 100%;
  height: 1px;
  background: #fff;
  position: relative;
  content: "";
  display: inline-block;
  top: -10px;
  left: 10px;
}

.uspfrst-add2 P {
  color: #fff;
  font-family: "Montserrat", sans-serif;
  font-size: 21px;
  font-style: normal;
  line-height: 30px;
  letter-spacing: -1.05px;
}

.uspfrst-add2>img {
  margin-bottom: 12px;
}

.uspfrst-add2 a {
  color: #fff;
  text-align: center;

  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: -0.7px;
  padding: 8px 30px;
  background: #e02b3f;
  border-radius: 30px;
  display: inline-block;
  margin-top: 5px;
}

.uspfrst-add2 a:hover {
  background: #1c4075;
  color: #fff;
}

.required-ducument {
  margin-bottom: 60px;
}

.requiredoc {
  display: flex;
  align-items: center;
  gap: 40px;
  justify-content: center;
  padding: 30px 0 50px;
  border-bottom: 1px solid #cdcdcd;
}

.ducumntbx {
  display: flex;
  align-items: center;
  gap: 15px;
  transition: all 0.5s ease-in-out;
}

.ducumntbx p {
  color: #333340;
  font-family: "Montserrat", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.required-ducument h2 {
  color: #000;
  text-align: center;
  font-family: "montserratbold";
  font-size: 59px;
  font-style: normal;
  font-weight: 700;
  line-height: 60px;
  letter-spacing: -3.54px;
  margin-bottom: 50px;
}

.docpic {
  border-radius: 20px;
  overflow: hidden;
}

.ducumntbx:hover .docpic {
  box-shadow: 0px 40px 60px 0px rgba(0, 0, 0, 0.25);
}

.ducumntbx:hover {
  transform: translateY(10px);
}

.usebtompd::after {
  background: linear-gradient(96deg, #193f76 5.94%, #f82f45 85.37%) !important;
}

.prtnrbnd::after {
  content: "";
  position: absolute;
  width: 13%;
  height: 471px;
  background-image: url("../images/ptrgtcar.png");
  right: 0;
  top: 110%;
  transform: translateY(-50%);
  background-size: contain;
  z-index: 1;
  background-repeat: no-repeat;
}

.prtnrbnd h2 {
  text-align: center;
}

.prtnrbnd .owl-nav {
  display: none !important;
}

.fincalpic {
  position: absolute;
  left: 0;
  bottom: 0;
  /* z-index: 1; */
}

.financfrom .bkfrom input {
  background: none;
  border: 1px solid #fff;
  color: #fff;
}

.financfrom .bkfrom input::placeholder {
  color: #fff;
}

.financfrom .bkfrom select {
  background: transparent url(../images/drp.png) no-repeat right 15px top 20px;
  border: 1px solid #fff;
  color: #fff;
}

.financfrom .bkfrom select option {
  color: #000;
}

.financfrom h2 {
  color: #fff;
}

.financfrom h2::before {
  background: #fff;
}

.fetureusp-info {
  padding: 80px 0 170px;
  width: 100%;
  display: block;
  overflow: hidden;
  position: relative;
}

.usebtompd {
  padding-bottom: 230px;
}

/**********************about**********************/
.sub-banner {
  position: relative;
}

.sub-banner img {
  padding-left: 0;
  padding-right: 0;
}

.sub-banner .subbannertext {
  position: absolute;
  top: 50%;
  transform: translatey(-50%);
}

.subbannertext h2 {
  color: #f82f45;
  font-family: "montserratbold";
  font-size: 59px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -2.36px;
}

.subbannertext .bluee {
  color: #193f76;
}

.bredcam {
  text-align: left !important;
}

.bredcam ul {
  display: inline-flex;
  padding-left: 0px;
}

.bredcam ul li {
  font-family: "Montserrat", sans-serif;
  text-decoration: none;
  list-style-type: none;
  margin-right: 30px;
  position: relative;
  color: #273431;
  font-size: 16px;
  font-weight: 500;
}

.bredcam ul li a {
  color: #273431;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
}

.bredcam ul li a::before {
  position: absolute;
  content: "";
  border: solid #273431;
  border-width: 0 1px 1px 0;
  padding: 4px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  right: -16px;
  top: 8px;
}

.aboutsecond {
  padding: 50px 0px;
}

.aboutcarText p {
  color: #273431;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
}

.LendingPartners ul {
  display: flex;
  padding-left: 0px;
  flex-wrap: wrap;
}

.LendingPartners ul li {
  list-style-type: none;
  width: 148px;
  height: 148px;
  border: 1px solid #d1e8ef;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 6px;
  background: #fff;
  stroke-width: 1.5px;
  stroke: #d1e8ef;
}

.LendingPartners ul li img {
  max-width: 95px;
}

.LendingPartners h4,
.InsurancePartners h4 {
  color: #000;
  font-family: "Montserrat", sans-serif;
  font-size: 49px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -2.36px;
  margin-bottom: 30px;
  text-align: center;
}

.LendingPartners {
  padding: 40px 50px 60px 50px;
  background-color: #d1e8ef;
  border-radius: 30px;
  position: relative;
}

.LendingPartners::before {
  position: absolute;
  content: "";
  background-image: url("../images/curve.png");
  bottom: 0;
  right: 0;
  z-index: -1;
}

.LendingPartners ul li:nth-last-child(1) {
  /* background: #981a1d !important; */
}

.InsurancePartners ul li {
  list-style-type: none;
  width: 200px;
  height: 145px;
  border-radius: 10%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 6px;
  background-color: #fff;
}

.InsurancePartners ul {
  display: flex;
  padding-left: 0px;
  flex-wrap: wrap;
}

.InsurancePartners {
  padding: 40px 50px 60px 50px;
  background-color: #ffecee;
  border-radius: 30px;
  position: relative;
}

.InsurancePartners::before {
  position: absolute;
  content: "";
  background-image: url("../images/curve1.png");
  bottom: 0;
  right: 0;
  z-index: -1;
}

.Partners {
  padding: 50px 0px;
}

.AboutDemo {
  padding: 0px 0px;
}

.AboutDemo p {
  color: #273431;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
}

.foundertext p {
  color: #273431;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
}

.aboutTeamone {
  padding: 40px 0px;
  position: relative;
}

.founder-nane {
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 10px;
  padding: 15px 0px;
  position: absolute;
  bottom: 1%;
  width: 85%;
  left: 3%;
}

.founder-nane h6 {
  color: #f82f45;
  text-align: center;
  font-family: "Montserrat", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
}

.founder-nane p {
  color: #333340;
  text-align: center;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 2px;
  text-transform: uppercase;
  margin-bottom: 0;
}

.founderimg {
  position: relative;
}

.founderimg img {
  width: 450px;
  height: 440px;
}

.aboutTeamone h2 {
  color: #f82f45;
  text-align: center;
  font-family: "montserratbold";
  font-size: 59px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -2.36px;
  margin-bottom: 50px;
}

.aboutTeamone .founderimg img {
  margin-top: 40px;
}

.aboutTeamone .row::before {
  position: absolute;
  content: "";
  background-color: #ffecee;
  width: 450px;
  height: 500px;
  left: 0;
  top: 17%;
  border-radius: 20px;
}

.teamtwo {
  background-color: #f2fcff;
  padding: 60px 0px;
  position: relative;
}

.teamftext p,
.teamstext p {
  color: #273431;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  margin: 30px 0;
}

.teamfst,
.teamsec {
  position: relative;
}

.teamfst .founder-nane,
.teamsec .founder-nane {
  background-color: #fff;
  border-radius: 10px;
  padding: 15px 0px;
  position: absolute;
  bottom: 1%;
  width: 65%;
  left: 1%;
}

.footertop {
  margin-bottom: 250px;
}

.aboutTeamtwo {
  position: relative;
  padding: 50px 0px;
}

.aboutTeamtwo .row::before {
  position: absolute;
  content: "";
  background-color: #ffecee;
  width: 450px;
  height: 500px;
  right: 0;
  top: 10%;
  border-radius: 20px;
}

.aboutTeamtwo .founderimg img {
  margin-top: 100px;
}

.borderbottom {
  border-bottom: 1px solid #dddddd;
  position: relative;
}

.borderbottom::before {
  position: absolute;
  content: "";
  background-color: #df1f33;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.teamtwo .container::before {
  position: absolute;
  content: "";
  background-color: #fff;
  width: 1695px;
  height: 950px;
  left: 0;
  top: 20%;
  border-radius: 10px;
}

/**********************about**********************/

/**********************about**********************/
.sub-banner {
  position: relative;
}

.sub-banner img {
  padding-left: 0;
  padding-right: 0;
  height: auto;
  width: auto;
}

.sub-banner .subbannertext {
  position: absolute;
  top: 50%;
  transform: translatey(-50%);
}

/* .subbannertext h2 {
  color: #f82f45;
  font-family: "Montserrat", sans-serif;
  font-size: 59px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -2.36px;
} */

.subbannertext .bluee {
  color: #193f76;
}

.aboutsecond {
  padding: 50px 0px;
}

.aboutcarText p {
  color: #273431;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
}

.LendingPartners ul {
  display: flex;
  padding-left: 0px;
  flex-wrap: wrap;
  justify-content: center;
}

.LendingPartners ul li {
  list-style-type: none;
  width: 148px;
  height: 148px;
  border: 1px solid #d1e8ef;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 6px;
  background: #fff;
  stroke-width: 1.5px;
  stroke: #d1e8ef;
}

.LendingPartners h4,
.InsurancePartners h4 {
  color: #000;
  font-family: "montserratbold";
  font-size: 49px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -2.36px;
  margin-bottom: 30px;
  text-align: center;
}

.LendingPartners {
  padding: 40px 50px 60px 50px;
  background-color: #d1e8ef;
  border-radius: 30px;
  position: relative;
}

.LendingPartners::before {
  position: absolute;
  content: "";
  background-image: url("../images/curve.png");
  bottom: 0;
  right: 0;
  z-index: -1;
}

.InsurancePartners ul li {
  list-style-type: none;
  width: 200px;
  height: 145px;
  border-radius: 10%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 6px;
  background-color: #fff;
}

.InsurancePartners ul {
  display: flex;
  padding-left: 0px;
  flex-wrap: wrap;
  justify-content: center;
}

.InsurancePartners {
  padding: 40px 50px 60px 50px;
  background-color: #ffecee;
  border-radius: 30px;
  position: relative;
}

.InsurancePartners::before {
  position: absolute;
  content: "";
  background-image: url("../images/curve1.png");
  bottom: 0;
  right: 0;
  z-index: -1;
}

.Partners {
  padding: 50px 0px;
}

.AboutDemo {
  padding: 0px 0px;
}

.AboutDemo p {
  color: #273431;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
}

.foundertext p {
  color: #273431;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
}

.aboutTeamone {
  padding: 40px 0px 150px;
  position: relative;
}

.founder-nane {
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 10px;
  padding: 15px 0px;
  position: absolute;
  bottom: 1%;
  width: 85%;
  left: 3%;
}

.founder-nane h6 {
  color: #f82f45;
  text-align: center;
  font-family: "Montserrat", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
}

.founder-nane p {
  color: #333340;
  text-align: center;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 2px;
  text-transform: uppercase;
  margin-bottom: 0;
}

.founderimg {
  position: relative;
}

.founderimg img {
  width: 450px;
  height: 440px;
}

/* .aboutTeamone h2 {
  color: #f82f45;
  text-align: center;
  font-family: "Montserrat", sans-serif;
  font-size: 59px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -2.36px;
  margin-bottom: 50px;
} */

.aboutTeamone .founderimg img {
  margin-top: 40px;
}

.aboutTeamone .row::before {
  position: absolute;
  content: "";
  background-color: #ffecee;
  width: 450px;
  height: 500px;
  left: 0;
  top: 17%;
  border-radius: 20px;
}

.teamtwo {
  background-color: #f2fcff;
  padding: 60px 0px;
  position: relative;
}

.teamftext p,
.teamstext p {
  color: #273431;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  margin: 30px 0;
}

.teamfst,
.teamsec {
  position: relative;
}

.teamfst .founder-nane,
.teamsec .founder-nane {
  background-color: #fff;
  border-radius: 10px;
  padding: 15px 0px;
  position: absolute;
  bottom: 1%;
  width: 65%;
  left: 1%;
}

.footertop {
  margin-bottom: 250px;
}

.aboutTeamtwo {
  position: relative;
  padding: 50px 0px;
}

.aboutTeamtwo .row::before {
  position: absolute;
  content: "";
  background-color: #ffecee;
  width: 450px;
  height: 500px;
  right: 0;
  top: 10%;
  border-radius: 20px;
}

.aboutTeamtwo .founderimg img {
  margin-top: 100px;
}

.borderbottom {
  border-bottom: 1px solid #dddddd;
  position: relative;
}

.borderbottom::before {
  position: absolute;
  content: "";
  background-color: #df1f33;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.teamtwo .container::before {
  position: absolute;
  content: "";
  background-color: #fff;
  width: 1695px;
  height: 950px;
  left: 0;
  top: 20%;
  border-radius: 10px;
}

.aboutcarText b {
  color: #193f76 !important;
  color: #193f76 !important;
  font-size: 16px;
  font-weight: 600;
}

.AboutDemo b {
  color: #193f76 !important;
  color: #193f76 !important;
  font-size: 16px;
  font-weight: 600;
}

/**********************about**********************/

.aboutcarText b span {
  color: #f82f45;
}

.our-team-sec {
  position: relative;
  margin: 100px 0 150px;
}

.our-team-sec::before {
  width: 100%;
  min-height: 106px;
  background: #193f76;
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  border-right: 32px solid #f82f45;
}

.aboutTeamone .our-team-info b {
  color: #193f76;
}

.our-team-info {
  display: block;
}

.our-team-info h3 {
  color: #fff;
  font-family: "Montserrat", sans-serif;
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 50px;
  padding-top: 28px;
  text-align: left;
}

.our-team-info h3 span {
  color: #86abe0;

  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 2.25px;
  text-transform: uppercase;
  display: block;
}

.our-team-info p {
  color: #273431;

  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  text-align: left;
}

.member-pic {
  float: left;
  margin-right: 78px;
  margin-top: -80px;
  position: relative;
  margin-bottom: 25px;
}

.member-pic img {
  max-width: 100%;
  border-radius: 20px;
}

.member-pic::before {
  position: absolute;
  left: 0;
  right: 0;
  bottom: -10px;
  margin: 0 auto;
  max-width: 360px;
  height: 60px;
  border-radius: 20px;
  background: #f82f45;
  content: "";
  z-index: -1;
}

.our-team-sec:nth-child(even) {}

.our-team-sec:nth-child(even) .member-pic {
  float: left;
  margin-right: 78px;
  position: relative;
}

.our-team-sec:nth-child(odd) {}

.our-team-sec:nth-child(odd) .member-pic {
  float: right;
  margin-left: 78px;
  margin-right: 0;
}

.our-team-sec:nth-child(odd)::before {
  border-left: 32px solid #f82f45;
  border-right: 0;
}

.our-team-sec:nth-child(odd) h3 {
  text-align: right;
}

.car-defination {
  position: relative;
  display: block;
  padding: 50px 0;
}

.Partners .container>.row {
  margin: 0 -15px;
}

.Partners .container>.row>div {
  padding: 0;
}

.car-defination {
  margin: 0 0 50px;
}

.subtxthdng {
  text-align: center;
  max-width: 1245px;
  margin: 0 auto;
}

.subtxthdng p {
  color: #133971;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  margin-bottom: 70px;
}

.subtxthdng p span,
.our-team-info p span {
  color: #f82f45;
}

.abutdetl p {
  color: #133971;

  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  margin-bottom: 30px;
  text-align: left;
}

.abutdetl p span {
  color: #f82f45;
}

.shpic {
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
  text-align: right;
}

.shpic img {
  max-width: 90%;
}

.carrpic {
  position: relative;
  padding-top: 50px;
}

.carrpic img {
  max-width: 90%;
}

.carrpic::before {
  content: "";
  background: linear-gradient(174deg,
      #e3f9ff 8.67%,
      rgba(242, 252, 255, 0) 61.91%);
  width: 450px;
  height: 450px;
  border-radius: 100%;
  position: absolute;
  left: -95px;
  top: -78px;
  z-index: -1;
}

.idfclr {
  background: #981a1d !important;
}

.Rcar {
  left: 0;
  right: auto;
}

@media only screen and (max-width: 1300px) {
  .map-wrapper {
    top: -15px;
  }

  .car-wrapper {
    top: -15px;
  }
}

.footer_menu ul li a {
  font-family: "Montserrat", sans-serif;
}

/* buy new car */

.new-car-section {
  padding-bottom: 180px;
  text-align: left;
}

.buy-new-car-banner {
  width: 100%;
  display: block;
  overflow: hidden;
  padding: 30px 0;
}

.buynew-inner {
  display: flex;
  justify-content: space-between;
  align-items: end;
}

.newbooking {
  max-width: 790px;
  width: 100%;
  flex: 0 0 790px;
}

.newbooking h2 {
  color: #1c3f75;
  font-family: "montserratbold";
  font-size: 80px;
  font-style: normal;
  font-weight: 700;
  line-height: 80px;
  letter-spacing: -3.2px;
  margin-bottom: 20px;
}

.newbooking h3 {
  color: #1c3f75;
  font-family: "montserratbold";
  font-size: 59px;
  font-style: normal;
  font-weight: 700;
  line-height: 60px;
  letter-spacing: -3.54px;
  margin-bottom: 30px;
}

.newbooking p {
  color: #1c3f75;

  font-family: "Montserrat", sans-serif;
  font-size: 19px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  padding-bottom: 40px;
  position: relative;
}

.newbooking p::after {
  content: "";
  width: 116px;
  height: 7px;
  background: #f82f45;
  position: absolute;
  left: 0;
  bottom: 14px;
}

.bkfrom input {
  width: 100%;
  border-radius: 68px;
  border: 1px solid #979797;
  height: 50px;
  color: #989898;

  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 50px;
  margin: 12px 0;
  padding: 0 15px;
}

.bkfrom input::placeholder {
  color: #989898;
}

.bkfrom select {
  width: 100%;
  border-radius: 68px;
  border: 1px solid #979797;
  height: 50px;
  color: #989898;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  margin: 12px 0;
  padding: 0 30px 0 15px;
  background: #fff;
  background: url(../images/drp.png) no-repeat right 15px top 20px;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
}

.bkfrom button {
  border-radius: 68px;
  background: #f82f45;
  padding: 16px 35px;
  color: #fff;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  border: none;
  margin-top: 15px;
}

.bkfrom button:hover {
  background: #134494;
  color: #fff;
}

/* .prefncbtn button:hover{
  background: #f82f45 !important;
} */

.quote-info {
  margin-top: 26px;
  padding: 60px 0;
  width: 100%;
  display: block;
  overflow: hidden;
  position: relative;
  background-image: url(../images/boxes.png);
}

.quote-info::after {
  content: "";
  background: linear-gradient(96deg, #f82f45 5.94%, #193f76 85.37%);
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  mix-blend-mode: multiply;
}

.quote-info .container {
  position: relative;
  z-index: 1;
}

.quote-info p {
  color: #fff;
  text-align: center;
  font-family: "Montserrat", sans-serif;
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: 48px;
  max-width: 1100px;
  margin: 0 auto;
}

.quote-info span {
  width: 80px;
  height: 80px;
  background: #fff;
  display: inline-block;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #00548f;
  transform: rotate(-180deg);
  margin: auto;
  position: relative;
  font-size: 30px;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.quotegol {
  position: relative;
  width: 130px;
  height: 130px;
  margin: 0 auto 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bigbdr svg {
  width: 115px;
  height: 115px;
}

.smlbdr svg {
  width: 100px;
  height: 100px;
}

.smlbdr {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  max-width: 100px;
  max-height: 100px;
}

.suspendisse-sec {
  width: 100%;
  display: block;
  overflow: hidden;
  padding: 60px 0;
}

.suspic {
  max-width: 139px;
  position: relative;
  margin: 0 auto 20px;
}

.ctgrypic {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ctgrypic img {
  max-width: 132px;
  width: auto !important;
}

.suspdibox {
  padding: 0 0 20px;
  text-align: center;
  position: relative;
}

.suspdibox h4 {
  color: #333340;
  text-align: center;

  font-family: "Montserrat", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 20px;
}

.suspdibox p {
  color: #273431;
  text-align: center;

  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
}

.suspendisse-sec .row>div {
  padding: 0 23px;
  position: relative;
}

.suspendisse-sec .row>div::before {
  position: absolute;
  bottom: 10px;
  left: 2px;
  right: -4.5px;
  height: 50%;
  border: 3px solid #40626c;
  content: "";
  border-radius: 25px;
  border-top: 0px;
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
}

.suspendisse-sec .row>div:nth-child(even)::before {
  bottom: 0;
  top: 10px;
  border-top: 3px solid #40626c;
  border-top-left-radius: 25px !important;
  border-top-right-radius: 25px !important;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  border-bottom: 0px;
}

.suspendisse-sec .row>div:nth-child(even) {
  padding-top: 60px;
}

.suspendisse-sec .row>div:first-child::after {
  position: absolute;
  width: 15px;
  height: 15px;
  background: #f82f45;
  content: "";
  left: -3px;
  top: 50%;
  border-radius: 100%;
  transform: translateY(-69%);
}

.suspendisse-sec .row>div:last-child::after {
  position: absolute;
  width: 15px;
  height: 15px;
  background: #193f76;
  content: "";
  right: -10px;
  top: 50%;
  border-radius: 100%;
  transform: translateY(-69%);
}

.susheding {
  margin-bottom: 50px;
}

.susheding h3 {
  color: #000;
  text-align: center;

  font-family: "Montserrat", sans-serif;
  font-size: 59px;
  font-style: normal;
  font-weight: 600;
  line-height: 60px;
  letter-spacing: -2.36px;
}

.susheding p {
  color: #273431;
  text-align: center;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  max-width: 704px;
  margin: 0 auto;
}

.susheding h3,
.susheding p {
  font-family: Montserrat, sans-serif !important;
  font-style: normal !important;
  font-weight: 700;
  text-align: center !important;
}

.newbuyclb img {
  max-width: 100%;
}

/* section 20-12-23 */

.urgntrequire {
  margin: 80px 0;
  position: relative;
  text-align: left;
}

.urgntrequire .container {
  position: relative;
  z-index: 1;
}

.urgntrequire img {
  max-width: 100%;
}

.urgntrequire h3 {
  font-family: "montserratbold";
  color: #000;
  font-size: 59px;
  font-style: normal;
  font-weight: 700;
  line-height: 64px;
  letter-spacing: -2.36px;
}

.urgntrequire h3 span {
  color: #193f76;
}

.urgntrequire a {
  color: #fff;
  text-align: center;

  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  display: inline-block;
  background: #f82f45;
  border-radius: 30px;
  padding: 15px 25px;
  margin-top: 15px;
}

.urgntrequire a:hover {
  background: #f3bd00;
  color: #1c3f75;
}

.urgntrequire p {
  color: #273431;

  font-family: "Montserrat", sans-serif;
  font-size: 19px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
}

.btompading h6 {
  display: block;
  color: #273431;

  font-family: "Montserrat", sans-serif;
  font-size: 19px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
}

.btompading {
  padding-bottom: 100px;
  overflow: hidden;
}

.btompading h3 span {
  color: #f82f45;
}

.loking-loan {
  position: relative;
}

.loking-loan img {
  max-width: 100%;
}

.lookdtl {
  position: absolute;
  left: 20px;
  top: 50%;
  transform: translateY(-50%);
}

.lookdtl span {
  display: block;
  margin-bottom: 12px;
}

/* .lookdtl h5 {
  color: #fff;

  font-family: "Montserrat", sans-serif;
  font-size: 37px;
  font-style: normal;
  font-weight: 500;
  line-height: 100.2%;
  letter-spacing: -1.48px;
} */
.lookdtl h4 {
  color: #e02b3f;
  font-family: "montserratbold";
  font-size: 58px;
  font-style: normal;
  font-weight: 700;
  line-height: 85.2%;
  letter-spacing: -2.36px;
}

.lookdtl h4 em {
  font-style: normal;
  font-weight: 500;
  display: block;
}

.lookdtl p {
  color: #fff;

  font-family: "Montserrat", sans-serif;
  font-size: 21px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: -1.05px;
}

/* .lookdtl a {
  color: #fff;
  text-align: center;

  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: -0.7px;
  border-radius: 30px;
  background: #1c4075;
  padding: 10px 30px 9px;
}
.lookdtl a:hover {
  background: #f3bd00;
  color: #1c3f75;
} */

/* .lookinfo {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
} */
.lookinfo span {
  display: block;
  margin-bottom: 12px;
}

.lookinfo h5 {
  color: #fff;
  font-family: "Montserrat", sans-serif;
  font-size: 37px;
  font-style: normal;
  font-weight: 500;
  line-height: 100.2%;
  letter-spacing: -1.48px;
}

.lookinfo h4 {
  color: #fff;
  font-family: "Montserrat", sans-serif;
  font-size: 50px;
  font-style: normal;
  font-weight: 800;
  line-height: 45px;
  letter-spacing: -2.32px;
  margin-bottom: 18px;
}

.lookinfo h4 em {
  font-style: normal;
  font-weight: 500;
  display: block;
}

.lookinfo p {
  color: #fff;
  font-family: "Montserrat", sans-serif;
  font-size: 26px;
  font-style: normal;
  font-weight: 500;
  line-height: 40px;
  letter-spacing: -1.04px;
  margin: 0;
}

/* .lookinfo a {
  color: #fff;
  text-align: center;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: -0.7px;
  border-radius: 30px;
  border: 1px solid #fff;
  background: #e02b3f;
  padding: 10px 35px 9px;
}
.lookinfo a:hover {
  background: #f3bd00;
  color: #1c3f75;
} */
.looking-option .container>.row {
  margin: 0 -45px;
}

.looking-option .container>.row>div {
  padding: 0 45px;
}

.preowner-sec {
  padding: 0 0;
  position: relative;
  /*  overflow: hidden;*/
}

.preowner-sec h3 {
  color: #000;
  font-family: "montserratbold";
  font-size: 59px;
  font-style: normal;
  font-weight: 700;
  line-height: 64px;
  letter-spacing: -2.36px;
}

.preowner-sec p {
  color: #273431;

  font-family: "Montserrat", sans-serif;
  font-size: 19px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
}

.preowner-sec a {
  border-radius: 68px;
  background: #f82f45;
  color: #fff;
  text-align: center;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  padding: 10px 30px;
  display: inline-block;
  margin-top: 10px;
}

.preowner-sec a:hover {
  background: #1c4075;
}

.preowner-sec .container {
  position: relative;
}

.preownrpic {
  position: absolute;
  left: -13%;
  top: -49%;
}

.preownrpic img {
  max-width: 100%;
}

.preownr-detl {
  padding: 290px 0 255px 35px;
  position: relative;
  z-index: 1;
}

/* .anglepic {
  position: absolute;
  left: 0;
  top: -27%;
}
.anglepic img{
  max-width: 100%;
} */
.looking-option {
  position: relative;
  z-index: 2;
  text-align: left;
}

.urgntrequire .aprochpic {
  position: absolute;
  right: 0;
  top: 0;
}

.fetureusp-info {
  background: url(../images/boxes.png),
    linear-gradient(96deg, #193f76 5.94%, #f82f45 85.37%);
}

.fetureusp-info .submitbtn {
  z-index: 999;
}

.uspbg {
  background: url(../images/boxes.png),
    linear-gradient(96deg, #f82f45 5.94%, #193f76 85.37%);
}

.fetursbx {
  width: 100%;
  text-align: center;
}

.feturd-pic {
  max-width: 140px;
  position: relative;
  width: 100%;
  margin: 0 auto 15px;
}

.feturd-pic img {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}

.fetursbx h5 {
  color: #fff;
  text-align: center;
  font-family: "montserratsemibold";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.fetursbx p {
  color: #fff;
  text-align: center;
  font-family: "montserratmedium";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
}

.fetureusp-info h2 {
  color: #fff;
  font-family: "montserratbold";
  font-size: 59px;
  font-style: normal;
  font-weight: 700;
  line-height: 64px;
  letter-spacing: -2.36px;
  margin-bottom: 50px;
  text-align: center;
}

.emiclbtn {
  margin-top: 60px;
  text-align: center;
}

.emiclbtn a {
  border-radius: 68px;
  background: #f82f45;
  padding: 16px 35px;
  color: #fff;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  border: none;
  margin-top: 15px;
}

.emiclbtn a:hover {
  background: #193f76;
  color: #fff;
}

.emiclbtn span {
  border-radius: 68px;
  background: #f82f45;
  padding: 16px 35px;
  color: #fff;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  border: none;
  margin-top: 15px;
  cursor: pointer;
}

.emiclbtn span:hover {
  background: #1c3f75;
  color: #fff;
}

/* .fetureusp-info{
  background: url('../images/boxes.png');
} */

.buy-sell-add {
  padding: 80px 0;
}

.buy-sell-add img {
  width: 100%;
}

.car-list {
  padding-bottom: 180px;
}

.urgntrequire {
  margin: 80px 0;
  position: relative;
}

.urgntrequire img {
  max-width: 100%;
}

.urgntrequire h3 {
  font-family: "montserratbold";
  color: #000;
  font-size: 56px;
  font-style: normal;
  font-weight: 700;
  line-height: 64px;
  letter-spacing: -2.36px;
}

.urgntrequire h3 span {
  color: #000;
}

.urgntrequire a {
  color: #fff;
  text-align: center;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  display: inline-block;
  background: #f82f45;
  border-radius: 30px;
  padding: 15px 25px;
  margin-top: 15px;
}

.urgntrequire a:hover {
  background: #1c4075;
  color: #fff;
}

.urgntrequire p {
  color: #273431;
  font-family: "Montserrat", sans-serif;
  font-size: 19px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
}

.urgntrequire h3 .redtext {
  color: #f82f45;
}

.urgntrequire h3 .bluetext {
  color: #193f76;
}

.redtext {
  color: #f82f45;
}

.bluetext {
  color: #193f76;
}

/*12-jan*/
.uspdd {
  padding: 0 0 40px 0;
  margin-bottom: 0;
}

.citybg {
  position: absolute;
  top: 0;
  right: 0;
  text-align: right;
}

.preabut {
  position: relative;
}

.preabut img {
  max-width: 93%;
}

.prbtshp {
  position: absolute;
  left: -104px;
  bottom: -96px;
  z-index: -1;
  max-width: 100% !important;
}

.precar-abut-info {
  display: flex;
  justify-content: space-between;
  max-width: 83%;
  margin: 0 auto;
}

.iconbx {
  background: #cfe8f0;
  width: 110px;
  height: 110px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 80px;
}

.iconbx:nth-child(2) {
  width: 148px;
  height: 148px;
  margin-top: 0;
}

.preownrusp-sec>.container>.row {
  margin: 60px 0 0;
}

.preownrusp-sec>.container>.row>.col {
  padding: 0;
}

.preownrusp-sec .container .row .col {
  padding: 0;
}

.preownrusp-sec>.container>.row>.col:nth-child(even) .shapebox {
  align-items: start;
}

.preownrusp-sec>.container>.row>.col:nth-child(odd) .shapebox {
  align-items: end;
}

.preownrusp-sec .emibtnlnk {
  position: relative;
  z-index: 9;
}

.preuspicon {
  max-width: 139px;
  position: relative;
  margin: 0 auto 20px;
}

.preuspicon img {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}

.preownr-uspbx-objct {
  min-height: 240px;
}

.preownr-uspbx-objct h6 {
  color: #333340;
  text-align: center;
  font-family: "Montserrat", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
  max-width: 192px;
  margin: 0 auto;
  min-height: 62px;
}

.preownr-uspbx-objct p {
  color: #273431;
  text-align: center;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
}

.ductmrgin {
  margin: 60px 0;
}

.mrgtp0 {
  margin-top: 0;
}

.adredbg {
  /*background: #E02B3F;
  mix-blend-mode: plus-darker;*/
  max-width: 292px;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.adredbg::before {
  background: #e02b3f;
  mix-blend-mode: darken;
  width: 100%;
  height: 100%;
  content: "";
  position: absolute;
}

.uspadd-box {
  border-radius: 20px;
  overflow: hidden;
}

.adredbg .lookdtl h3 {
  color: #fff;
  font-family: "montserratbold";
  font-size: 45px;
  font-style: normal;
  font-weight: 700;
  line-height: 49.5px;
  letter-spacing: -1.833px;
  text-transform: uppercase;
}

.preownrbgnon {
  padding: 100px 0 200px;
  background-repeat: no-repeat !important;
}

.preownrbgnon::after {
  display: none;
}

.otobxemply {
  position: absolute;
  left: 40px;
  bottom: 80px;
  z-index: 1;
  max-width: 268px;
  width: 100%;
}

.otobxemply img {
  max-width: 100%;
}

.otobxemply::after {
  width: 100%;
  position: absolute;
  left: 17px;
  bottom: -39px;
  background: linear-gradient(3deg,
      #193f76 37.67%,
      rgba(25, 63, 118, 0) 89.15%);
  content: "";
  min-height: 172px;
}

.midle-line {
  position: relative;
  background: #40626c;
  width: 100%;
  height: 3px;
  margin: 30px 0;
}

.midle-line::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 21px;
  height: 21px;
  border-radius: 100%;
  background: #f82f45;
  border: 5px solid #40626c;
  top: 50%;
  transform: translateY(-50%);
}

.shapebox {
  width: 100%;
  min-height: 240px;
  display: flex;
  align-items: end;
  justify-content: center;
}

.roadpic img {
  max-width: 100%;
}

.preownrusp-sec {
  padding-bottom: 30px;
}

.emibtnlnk {
  display: block;
  text-align: center;
  margin-top: 30px;
}

.emibtnlnk a {
  background: #f82f45;
  border-radius: 50px;
  padding: 15px 30px;
  color: #fff;
  text-align: center;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  display: inline-block;
  cursor: pointer;
}

.emibtnlnk a:hover {
  background: #193f76;
  color: #fff;
}

.emibtnlnk span {
  background: #f82f45;
  border-radius: 50px;
  padding: 15px 30px;
  color: #fff;
  text-align: center;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  display: inline-block;
  cursor: pointer;
}

.emibtnlnk span:hover {
  background: #193f76;
  color: #fff;
}

.roadpic {
  position: relative;
  margin-top: -120px;
  text-align: center;
}

/*2 feb*/

.shapeicon {
  max-width: 139px;
  position: relative;
  margin: 0 auto 20px;
}

.shapeicon img {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}

.welth-info {
  width: 100%;
  position: relative;
  border-radius: 15px;
  overflow: hidden;
  margin-bottom: 30px;
}

.welth-info>img {
  max-width: 100%;
  width: 100%;
}

.backshadow {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 27.72%, #000000 96.78%);
}

.wlth-detls {
  text-align: center;
}

.wlth-detls h6 {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 20px;
  color: #fff;
}

.wlth-detls p {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 16px;
  color: #fff;
  max-width: 82%;
  margin: 0 auto;
  line-height: 26px;
  min-height: 150px;
}

.usemrgn {
  margin-bottom: 150px;
}

.clkhre {
  background: #e02b3f !important;
  color: #fff !important;
}

.clkhre2 {
  background: #fff !important;
  color: #e8312e !important;
}

.rdusbdr {
  border-radius: 20px;
  overflow: hidden;
}

.finacial-achive {
  text-align: center;
}

.finacial-achive h6 {
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  color: #000;
  font-size: 40px;
}

.finacial-achive h3 {
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  color: #000;
  font-size: 55px;
  margin-top: 10px;
  text-transform: capitalize;
}

.finacial-achive h3 span {
  color: #f82f45;
}

.welthbody-sec {
  background: #f2fcff;
  padding: 80px 0 50px;
  margin-bottom: 50px;
}

.welthbody-sec .bluetext {
  color: #193f76;
}

.finacial-achive {
  margin: 0 0 70px 0;
}

.intrested-link {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
  padding: 60px 0;
  flex-wrap: wrap;
}

.intrested-link h5 {
  margin: 0;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  font-size: 60px;
  color: #000;
}

.intrested-link a {
  color: #fff;
  text-align: center;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: -0.7px;
  border-radius: 30px;
  background: #f82f45;
  padding: 10px 40px 9px;
}

.intrested-link a:hover {
  background: #193f76;
  color: #fff;
}

.intrested-link img {
  position: relative;
  margin-top: -70px;
}

.preadvrtise {
  position: relative;
}

.pretxt-add {
  position: absolute;
  top: 18px;
  right: 0;
  width: 100%;
  max-width: 224px;
}

.pretxt-add h5 {
  color: #fff;
  font-family: "montserratbold";
  font-size: 45px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: -2.36px;
  position: relative;
}

.pretxt-add h5 span {
  background: #f82f45;
  border-radius: 5px;
  padding: 0 6px 7px;
}

.pretxt-add P {
  color: #fff;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-style: normal;
  margin-bottom: 10px;
  letter-spacing: -1.05px;
}

.pretxt-add a {
  color: #fff;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: -0.7px;
  padding: 8px 30px;
  background: #e02b3f;
  border-radius: 30px;
  display: inline-block;
  margin-top: 5px;
}

.pretxt-add a:hover {
  background: #1c4075;
  color: #fff;
}

.pretxt-add img {
  max-width: 100%;
  width: auto;
  margin-bottom: 8px;
}

.presell {
  position: absolute;
  left: 20px;
  top: 50%;
  transform: translateY(-50%);
}

.presell span {
  display: block;
  margin-bottom: 12px;
}

.presell span img {
  width: auto;
  max-width: 100%;
}

.presell h5 {
  color: #191d21;
  font-family: "Montserrat", sans-serif;
  font-size: 22px;
  font-weight: 500;
  line-height: 45px;
  letter-spacing: 0.29em;
}

.presell h4 {
  color: #e02b3f;
  font-family: "montserratbold";
  font-size: 58px;
  font-style: normal;
  font-weight: 700;
  line-height: 85.2%;
  letter-spacing: -2.36px;
}

.presell p {
  color: #191d21;
  font-family: "Montserrat", sans-serif;
  font-size: 21px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: -1.05px;
}

.presell a {
  color: #fff;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: -0.7px;
  border-radius: 30px;
  background: #1c4075;
  padding: 10px 30px 9px;
}

.presell a:hover {
  background: #e02b3f;
  color: #fff;
}

.buycarinsu {
  position: absolute;
  right: 0;
  top: 15px;
  transform: inherit;
  text-align: center;
  left: 0;
}

.buycarinsu p {
  font-family: "Montserrat", sans-serif;
  font-size: 22px;
  font-weight: 500;
  line-height: 49px;
  letter-spacing: 0.29em;
  text-align: center;
}

.buycarinsu h4 {
  color: #fff;
  text-align: center;
  font-family: "Montserrat", sans-serif;
  font-size: 48px;
  font-style: normal;
  font-weight: 800;
  line-height: 49px;
  letter-spacing: -1.92px;
}

.buycarinsu a {
  font-size: 15px;
  padding: 10px 35px 9px;
  border: none;
}

.checkInventory .icon-Vector-14 {
  /* position: absolute !important; */
  /* top: 56% !important; */
}

/*5-mar*/

.inventry-sec {
  padding: 60px 0 150px;
}

.inventry-sec .selectsorttypecls {
  background: #ffffff;
  border: 1px solid #dbdbdb;
  box-shadow: 20px 45px 64px rgba(0, 0, 0, 0.13);
  border-radius: 68px;
  padding: 10px 10px 10px 10px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  color: #3f3f3f;
  outline: 0;
}

.sortby-sec {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sortby-sec select {
  background: #fff;
  border: 1px solid #ccc;
  padding: 5px 15px;
}

.invntry-bx {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.13);
  font-family: "Montserrat", sans-serif;
  margin: 12px 0;
}

.invntry-pic {
  margin-bottom: 15px;
  position: relative;
}

.invntry-dtls h5 {
  font-size: 20px;
  color: #000;
  font-weight: 600;
  text-align: left;
}

.invntry-dtls h6 {
  margin-top: 15px;
  color: #193f76;
  font-weight: 600;
  font-size: 20px;
}

.invntry-dtls h6 span {
  color: #555555;
}

.invntry-pic img {
  width: 100%;
}

.invntry-dtls {
  padding: 12px;
}

.invntry-dtls a {
  display: inline-block;
  border-radius: 68px;
  background: #f82f45;
  padding: 11px 20px;
  color: #fff;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  border: none;
  margin-top: 10px;
}

.invntry-dtls a:hover {
  background: #193f76;
  color: #fff;
}

.invntry-dtls p {
  color: #273431;
  font-family: Montserrat, sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 25px;
  text-align: left;
}

div.inventory .options-primary {
  position: relative;
  left: 234px;
  top: -5px;
  float: left;
  width: 230px;
  border: 0;
  table-layout: fixed;
  margin: auto;
  display: inline-block;
  background: rgba(0, 0, 0, 0);
  text-shadow: none;
  box-shadow: none;
}

div.inventory .options-secondary {
  position: relative;
  left: 245px;
  top: -5px;
  float: left;
  width: 250px;
  border: 0;
  table-layout: fixed;
  margin: auto auto 35px auto;
  display: inline-block;
  background: rgba(0, 0, 0, 0);
  text-shadow: none;
  box-shadow: none;
}

div.inventory .options-secondary tr,
div.inventory .options-secondary tr td,
div.inventory .options-primary tr td {
  padding-left: 0;
}

div.inventory .options-primary,
div.inventory .options-secondary,
div.inventory .options-primary tr td,
div.inventory .options-secondary tr td {
  background: rgba(0, 0, 0, 0);
}

.option,
div.inventory .price b {
  font-weight: 700;
  line-height: 18px;
  color: #3d3d3d;
  font-size: 12px;
}

table td.primary,
table .primary,
.post-entry table td.primary,
.post-entry table .primary {
  width: 90px;
  white-space: nowrap;
  border: 0;
  padding-right: 8px;
}

table td.secondary,
table .secondary,
.post-entry table td.secondary,
.post-entry table .secondary {
  width: 92px;
  white-space: nowrap;
  padding: 0;
  border: 0;
  padding-right: 8px;
}

table td.spec,
table .spec,
.post-entry table td.spec,
.post-entry table .spec {
  line-height: 18px;
  color: #3d3d3d;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 150px;
}

table td.spec,
table .spec,
.post-entry table td.spec,
.post-entry table .spec {
  color: #555555;
}

.options-primary td {
  font-size: 13px;
  text-shadow: none;
  padding-top: 5px;
}

.price {
  position: absolute;
  margin: 3px 0 33px 0;
  right: 50px;
  float: left;
  color: #3d3d3d;
  top: 46px;
}

.price b {
  display: block;
}

.outer {
  margin: 0 auto 30px;
  max-width: 1200px;
}

#big .item {
  background: #404040;
  padding: 0 0px;
  margin: 0;
  color: #fff;
  border-radius: 10px;
  text-align: center;
  width: 100%;
}

#thumbs .item {
  height: auto;
  padding: 0px;
  margin: 2px;
  color: #fff;
  /* border-radius: 10px; */
  text-align: center;
  cursor: pointer;
  overflow: hidden;
  margin: 0 10px;
}

#thumbs .item h1 {
  font-size: 18px;
}

#thumbs .current .item {
  background: #ff5722;
}

.owl-theme .owl-nav [class*="owl-"] {
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.owl-theme .owl-nav [class*="owl-"].disabled:hover {
  background-color: #d6d6d6;
}

#big.owl-theme {
  position: relative;
}

#big.owl-theme .owl-next,
#big.owl-theme .owl-prev {
  background: #333;
  width: 22px;
  line-height: 40px;
  height: 40px;
  margin-top: -20px;
  position: absolute;
  text-align: center;
  top: 50%;
}

#big.owl-theme .owl-prev {
  left: 10px;
  display: none;
}

#big.owl-theme .owl-next {
  right: 10px;
  display: none;
}

#thumbs.owl-theme .owl-next,
#thumbs.owl-theme .owl-prev {
  /* background: #333; */
  width: 30px;
  height: 30px;
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 50%;
  color: #000;
  top: 50%;
  transform: translateY(-58%);
  display: none;
}

#thumbs.owl-theme .owl-next,
#thumbs.owl-theme .owl-prev:hover {
  border: 1px solid #ccc;
  border-radius: 50%;
  color: #000;
  display: none;
}

.outer .owl-theme .owl-dots {
  display: none;
}

#thumbs .item img {
  width: 100%;
  height: 88px;
  border-radius: 10px;
  object-fit: contain;
}

#thumbs .item p {
  font-size: 16px;
  color: #000;
  margin-top: 5px;
}

#thumbs {
  margin-top: 20px;
  padding: 0 30px;
}

#thumbs.owl-theme .owl-next {
  position: absolute;
  left: -10px;
}

#thumbs.owl-theme .owl-prev {
  position: absolute;
  right: -10px;
}

.dtlspic {
  height: 480px;
  background: #f7f7f7;
  border: 1px solid #e3e3e3;
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  /*  cursor: zoom-in;*/
}

.dtlspic img {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  object-fit: contain;
}

.details-info-page {
  border: 2px solid #f82f45;
  padding: 15px;
  border-radius: 10px;
}

.details-info-page h3 {
  font-size: 30px;
  font-weight: 600;
  color: #000;
}

.info-label {
  display: flex;
  align-items: center;
  padding: 8px 0;
}

.info-label label {
  font-size: 15px;
  color: #000;
  max-width: 130px;
  width: 100%;
}

.info-label span {
  font-size: 15px;
  color: #666;
  padding-left: 10px;
}

.related-item {
  padding: 50px 0;
}

.related-item h3 {
  font-size: 59px;
  color: #000;
  margin-bottom: 30px;
  font-weight: 700;
}

.related-item .owl-nav {
  text-align: center;
  margin-top: 20px;
}

.related-item .owl-nav button {
  margin: 0 8px;
}

.contactussection {
  margin-top: 25px;
}

#emiCalculateModal .modal-title {
  font-family: "Montserrat", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  color: #193f76;
}

#emiCalculateModal .modal-body .form-control {
  height: 50px;
}

.inventory_section {
  padding: 60px 0;
}

.inventory_section h1 {
  font-family: "montserratbold";
  font-style: normal;
  font-weight: 700;
  font-size: 59px;
  line-height: 60px;
  letter-spacing: -2.36px;
  color: #193f76;
  text-align: center;
}

.inventory_section h2 {
  letter-spacing: -2.36px;
}

.work_heading h2 {
  font-size: 45px;
  line-height: 60px;
  color: #000;
  font-family: "montserratbold";
  font-weight: 700;
}

.about_content p {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 30px;
  text-align: center;
  color: #273431;
}

.about_content b {
  color: #f82f45 !important;
}

.about_content b span {
  color: #193f76;
}

.sellcarpic img {
  max-width: 100%;
}

.insurance-detl {
  max-width: 820px;
  margin: 20px auto;
}

.insurance-detl p {
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  color: #fff;
}

.insurance-detl ul {
  list-style: none;
  text-align: center;
}

.insurance-detl ul li {
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  display: inline-block;
  color: #fff;
  position: relative;
  padding-left: 40px;
  margin: 10px 20px;
}

.insurance-detl ul li span {
  width: 27px;
  height: 27px;
  position: absolute;
  background: #fff;
  border-radius: 100%;
  left: 0;
  color: #123260;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 25px;
}

.types-insurance {
  padding: 80px 0;
  position: relative;
  overflow: hidden;
}

.types-insurance h2 {
  font-family: "montserratbold";
  font-size: 59px;
  font-weight: 700;
  line-height: 72px;
  letter-spacing: -0.04em;
  text-align: center;
  color: #000;
  margin-bottom: 50px;
}

.type-bx {
  max-width: 980px;
  width: 100%;
  margin: 0 auto;
}

.inssurance-bx {
  width: 100%;
  border: 1px solid #f82f45;
  /*  border-image-source: linear-gradient(134.17deg, #F82F45 5.14%, #193F76 82.31%);*/
  padding: 30px 40px;
  text-align: center;
  border-radius: 15px;
}

.inssurance-bx h6 {
  font-family: "Montserrat", sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  color: #f82f45;
  margin-bottom: 20px;
}

.inssurance-bx p {
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 30px;
  color: #f82f45;
}

.insutyp-icon {
  margin-bottom: 35px;
}

.insutyp-icon span {
  display: inline-flex;
  width: 146px;
  height: 146px;
  align-items: center;
  justify-content: center;
  border: 1px solid #f82f45;
  border-radius: 100%;
}

.comprshiv {
  border: 1px solid #193f76;
}

.comprshiv .insutyp-icon span {
  border: 1px solid #193f76;
}

.comprshiv h6 {
  color: #193f76;
}

.comprshiv p {
  color: #193f76;
}

.insuranc-prtnr {
  background: #fff;
}

.insuranc-prtnr::after {
  content: "";
  position: absolute;
  width: 13%;
  height: 471px;
  background-image: url("../images/inprtnr.png");
  right: 0;
  top: 90%;
  transform: translateY(-50%);
  background-size: contain;
  z-index: 1;
  background-repeat: no-repeat;
}

.insuranc-prtnr .owl-nav {
  display: none !important;
}

.insuranc-prtnr a {
  display: inline-block;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: -0.05em;
  color: #fff;
  background: #f82f45;
  padding: 10px 40px;
  border-radius: 30px;
  margin-top: 30px;
}

.insuranc-prtnr a:hover {
  background: #1c4075;
  color: #fff;
}

.insuadinfo {
  max-width: 300px;
  transform: inherit;
  top: 20px;
}

.infopdL {
  padding-left: 85px;
}

.insuadinfo h4 {
  color: #e02b3f;
  font-family: "Montserrat", sans-serif;
  font-size: 25px;
  /* text-transform: uppercase; */
  line-height: 30px;
  font-weight: 600;
  letter-spacing: 0.02em;
  margin: 0;
}

.insuadinfo h6 {
  color: #000;
  font-family: "Montserrat", sans-serif;
  font-size: 22px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0.22em;
}

.insuadinfo h5 {
  color: #1c4075;
  font-size: 55px;
  font-style: normal;
  line-height: 85.2%;
  font-weight: 700;
  margin-bottom: 15px;
}

.insuadinfo p {
  color: #000;
  font-size: 17px;
}

.insuadinfo .infopdL a {
  background: #1c4075;
  border: none;
}

.insuadinfo .infopdL a:hover {
  background: #f82f45;
  color: #fff;
}

.insurcar-sell {
  transform: inherit;
  left: auto;
  top: 20px;
  right: 0;
  width: 100%;
  text-align: center;
}

.adtxtshw-rght {
  position: absolute;
  right: 30px;
  text-align: right;
}

.adtxtshw-rght h4 {
  color: #1c4075;
  text-transform: capitalize;
}

.insurcar-sell h5 {
  color: #e02b3f;
}

.adtxtshw-rght a {
  background: #f82f45;
  color: #fff;
}

.adtxtshw-rght a:hover {
  background: #1c4075;
  color: #fff;
}

.adtxtshw-rght p {
  color: #273431;
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  letter-spacing: -1.05px;
  line-height: 30px;
}

.notshowpic::before {
  display: none;
}

.notshowpic::after {
  display: none;
}

.polici-sec {
  padding: 80px 0;
  z-index: 2;
  position: relative;
  background: #f2fcff;
  margin-top: 60px;
}

.polici-sec h3 {
  font-family: "montserratbold";
  font-size: 52px;
  font-weight: 700;
  line-height: 60px;
  letter-spacing: -0.04em;
  text-align: center;
  color: #000000;
}

.polici-sec p {
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: center;
  color: #273431;
  max-width: 786px;
  margin: 0 auto;
}

.polici-bx {
  width: 100%;
  padding: 30px;
  text-align: center;
  position: relative;
  color: #fff;
  z-index: 2;
}

.polici-bx p {
  font-family: "Montserrat", sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  color: #fff;
  margin-bottom: 30px;
}

.polici-bx h5 {
  font-family: "Montserrat", sans-serif;
  font-size: 36px;
  font-weight: 600;
  line-height: 60px;
  letter-spacing: -0.04em;
  text-align: center;
  color: #fff;
  opacity: 0.4;
}

.polies-sec {
  margin-top: 50px;
}

.polies-sec .row .col {
  padding: 0 20px;
}

.redbx {
  background: #f82f45;
}

.redbx::after {
  clip-path: polygon(0px 74.93%, 0px 0px, 100% 18.31%, 100% 100%);
  position: absolute;
  left: 100%;
  top: 0;
  content: "";
  background: rgba(248, 47, 69, 0.2);
  width: 40px;
  height: 100%;
}

.polies-sec .row .col:last-child .redbx:last-child::after {
  display: none;
}

.policicon {
  max-width: 123px;
  position: relative;
  width: 100%;
  margin: 0 auto 30px;
}

.policicon svg {
  filter: drop-shadow(0px 36px 40px #00000026);
}

.policicon img {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}

.redbx .umbrecar {
  background: #f82f45;
}

.umbrecar {
  /* clip-path: polygon(11% 50%, 0 50%, 50% 0, 100% 50%, 89% 51%, 89% 100%, 11% 100%); */
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  height: 0;
  width: 100%;
  z-index: 1;
  transition: all 0.3s ease-in-out;
  transform: translateY(-99%);
  opacity: 0;
  visibility: hidden;
  z-index: -1;
}

.umbrecar span {
  bottom: 100%;
  position: absolute;
  width: 120%;
  left: -10%;
}

.umbrecar span img {
  width: 100%;
}

.polici-bx:hover .umbrecar {
  height: 120px;
  opacity: 1;
  visibility: visible;
}

.chatacar {
  position: relative;
  bottom: 70%;
}

.blubx {
  background: #193f76;
}

.blubx::after {
  clip-path: polygon(0px 74.93%, 0px 0px, 100% 18.31%, 100% 100%);
  position: absolute;
  left: 100%;
  top: 0;
  content: "";
  background: rgba(25, 63, 118, 0.2);
  width: 40px;
  height: 100%;
}

.blubx .umbrecar {
  background: #193f76;
}

.isuranceoption {
  margin: 0 -20px !important;
}

.isuranceoption>div {
  padding: 0 20px !important;
}

.asistantpic {
  position: relative;
  max-width: 605px;
  width: 100%;
  float: right;
}

.asistantpic::before {
  content: "";
  width: 222px;
  height: 302px;
  background: #f82f45;
  position: absolute;
  bottom: -25px;
  left: -25px;
  z-index: -1;
  border-radius: 20px;
}

.asistantpic img {
  border-radius: 20px;
  max-width: 100% !important;
}

.spacadjust {
  padding-bottom: 60px;
}

.advantage-sec {
  width: 100%;
}

.asistnt-srvc-bx {
  text-align: center;
  margin: 40px 0;
  padding: 0 20px;
}

.asistnt-srvc-bx .iconusp {
  max-width: 139px;
  margin: 0 auto;
}

.asistnt-srvc-bx .uspfeture-objctt {
  margin-top: 15px;
}

.iconusp em {
  width: 29px;
  height: 29px;
  border-radius: 100%;
  background: #f82f45;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #f2fcff;
  font-size: 15px;
  font-style: normal;
  position: absolute;
  margin: 0 auto;
  right: 0;
  left: 0;
  top: -14px;
}

.howto-service {
  padding: 0;
  background: none;
}

.howto-service::after {
  content: "";
  background: linear-gradient(96deg, #f82f45 5.94%, #193f76 85.37%);
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  mix-blend-mode: multiply;
}

.howto-service .container {
  position: relative;
  z-index: 1;
}

.howtosrvc {
  padding-left: 50px;
}

.howtosrvc ul {
  list-style: none;
  padding: 0;
}

.howtosrvc ul li {
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  display: inline-block;
  color: #fff;
  position: relative;
  padding-left: 40px;
  margin: 10px 0;
  width: 100%;
}

.howtosrvc ul li span {
  width: 27px;
  height: 27px;
  position: absolute;
  background: #fff;
  border-radius: 100%;
  left: 0;
  color: #123260;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 25px;
}

.howtosrvc h3 {
  font-family: "montserratbold";
  font-size: 59px;
  font-weight: 700;
  line-height: 64px;
  letter-spacing: -0.04em;
  text-align: left;
  color: #fff;
}

.hwtobok-pic {
  padding-left: 55px;
}

.hwtobok-pic img {
  max-width: 100%;
}

.carservices-add {
  padding: 60px 0;
  margin-bottom: 30px;
}

.carservices-add .adredbg::before {
  display: none;
}

.carservices-add .uspfrst-add2 {
  right: auto;
  left: 20px;
}

.carservices-add .adredbg .lookdtl h3 {
  color: #fff;
  font-family: "montserratbold";
  font-size: 45px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: -2.36px;
  position: relative;
  text-transform: capitalize;
}

.carservices-add .lookdtl a {
  background: #e02b3f;
  margin-top: 10px;
  display: inline-block;
  padding: 8px 30px;
  line-height: normal;
}

.carservices-add .lookdtl a:hover {
  background: #134494;
  color: #fff;
}

.carservices-add .uspfrst-add2 h5 {
  color: #fff;
  font-family: "montserratbold";
  font-size: 38px;
  font-style: normal;
  font-weight: 700;
  line-height: 49px;
  position: relative;
  letter-spacing: normal;
}

.carservices-add .uspfrst-add2 h5::after {
  display: none;
}

.carservices-add .uspfrst-add2 {
  max-width: 295px;
}

.carservices-add .uspfrst-add2 P {
  color: #fff;
  font-family: "Montserrat", sans-serif;
  font-size: 21px;
  font-style: normal;
  letter-spacing: -1.05px;
  line-height: 30px;
}

.carservices-add .uspfrst-add2 a {
  background: #1c4075;
  color: #fff;
}

.carservices-add .uspfrst-add2 a:hover {
  background: #ff3a4f;
  color: #fff;
}

.major-services-sec {
  margin-bottom: 50px;
}

.major-info {
  display: flex;
  justify-content: space-between;
}

.majorinfo-dtl {
  max-width: 358px;
  flex: 0 0 358px;
}

.majorinfo-dtl .udpfeture-bx {
  align-items: center;
}

.major-pic {
  padding: 0 30px;
  text-align: center;
}

.major-pic img {
  max-width: 100%;
}

.major-pic a {
  background: #f82f45;
  border-radius: 30px;
  color: #fff;
  cursor: pointer;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  padding: 12px 30px;
  text-align: center;
  display: inline-block;
  margin-top: 30px;
}

.major-pic a:hover {
  background: #1c4075;
  color: #fff;
}

.warrenty-info {
  padding-bottom: 130px;
  margin-top: -16px;
}

.warrenty-info {
  background: url(../images/benifits-bgpic.jpg);
}

.warrenty-info .loan-from {
  max-width: 100%;
}

.wrntycard {
  width: 310px;
  height: 310px;
  flex: 0 0 310px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(180deg, #0d2544 0%, rgba(13, 37, 68, 0) 91.94%);
}

.wrntycard span {
  width: 172px;
  height: 172px;
  border-radius: 100%;
  background: #cfe8f0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.wrnty-list {
  display: flex;
  justify-content: space-between;
  margin-top: 60px;
}

.wrntydtl {
  max-width: 364px;
  flex: 0 0 364px;
}

.wrntydtl ul {
  list-style: none;
  padding: 20px 0;
}

.wrntydtl ul li {
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
  color: #fff;
  display: inline-block;
  width: 100%;
  margin: 8px 0;
  position: relative;
  padding-left: 35px;
}

.wrntydtl ul li span {
  width: 25px;
  height: 25px;
  position: absolute;
  background: #fff;
  border-radius: 100%;
  left: 0;
  color: #123260;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 25px;
}

.wcarshw {
  width: 100%;
  text-align: center;
  position: relative;
  margin-top: -98px;
}

.wcarshw img {
  max-width: 100%;
}

.rto-service {
  padding-bottom: 40px;
  border-bottom: 1px solid #3d6298;
}

.rtoinfo .iconusp {
  max-width: 139px;
  margin: 0 auto;
}

.rtoinfo {
  text-align: center;
}

.rtoinfo p {
  font-family: "Montserrat", sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
  color: #fff;
  margin-top: 20px;
}

.botom-assitence {
  max-width: 902px;
  margin: 0 auto;
  display: flex;
}

.asistpic {
  margin-top: -30px;
}

.asisdtl {
  padding-left: 40px;
  margin-top: 35px;
}

.asisdtl h4 {
  font-family: "montserratbold";
  font-size: 59px;
  font-weight: 700;
  line-height: 70px;
  letter-spacing: -0.06em;
  text-align: left;
  color: #fff;
}

.cnctlink {
  display: flex;
  align-items: center;
  gap: 20px;
}

.cnctlink p {
  font-family: "Montserrat", sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 60px;
  letter-spacing: -0.06em;
  color: #fff;
  margin: 0;
}

.cnctlink a {
  background: #f82f45;
  border-radius: 30px;
  color: #fff;
  cursor: pointer;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  padding: 12px 30px;
  text-align: center;
  display: inline-block;
}

.cnctlink a:hover {
  background: #134494;
  color: #fff;
}

.usetopbtom {
  margin-bottom: 0;
}

.sellcarcds h4 {
  font-size: 52px !important;
}

.sellacartopbanner h6 {
  display: block;
  color: #273431;
  font-family: "Montserrat", sans-serif;
  font-size: 19px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
}

@-webkit-keyframes autofill {
  to {
    background: transparent;
  }
}

input:-webkit-autofill {
  -webkit-animation-name: autofill;
  -webkit-animation-fill-mode: both;
}

.cntrtxt {
  justify-content: center !important;
}

.cntrtxt h2 {
  font-family: "Montserrat", sans-serif !important;
  font-weight: 700 !important;
}

.how_works_section .work_heading h2 {
  letter-spacing: normal;
  font-size: 59px;
  letter-spacing: -0.04em;
}

@media (max-width: 767px) {
  .dtlspic {
    height: 340px;
  }

  #thumbs .item {
    height: 65px;
    margin: 0 8px;
  }

  .reviewcontainer .reviewouter .reviewstp .reviewstpheading {
    font-size: 34px;
  }

  .inner-banner-sec {
    margin-bottom: 28px;
  }
}

@media (max-width: 575px) {
  #thumbs {
    margin-top: 15px;
    padding: 0 12px;
  }

  #thumbs .item {
    height: 55px;
  }

  .dtlspic {
    height: 200px;
  }

  .related-item h3 {
    font-size: 32px;
  }
}

/* Responsive */
@media only screen and (min-width: 1600px) {
  .container {
    max-width: 1560px;
  }
}

@media only screen and (max-width: 1599px) {
  .member-pic {
    max-width: 420px;
  }

  .Rcar {
    max-width: 38% !important;
    top: -55px;
  }

  .banner_content {
    /* top: 20px; */
    top: 3px;
    padding: 10px 10px;
  }

  .banner_content h1 {
    font-size: 65px;
  }

  .header_email span.icon,
  .header_phone span.icon {
    width: 30px;
    height: 30px;
    line-height: 35px;
  }

  .header_email span.icon:before,
  .header_phone span.icon:before {
    font-size: 40px;
  }

  .header_menu ul.slimmenu li a {
    font-size: 12px;
    padding: 7px 14px;
  }

  .site_logo {
    width: auto;
  }

  .site_logo img {
    max-width: 100%;
    width: auto;
    height: auto;
  }

  .header_menu ul.slimmenu li .sub-toggle {
    top: 48%;
    padding-left: 2px;
  }

  .header_email .email_text,
  .header_phone .phone_text {
    font-size: 12px;
  }

  .how_works_section {
    padding: 35px 0 135px;
  }

  .work_heading h2,
  .otobix_container h2,
  .about_container h2,
  .testimonial_container h2,
  .faq_heading h2 {
    font-size: 46px;
    line-height: 60px;
  }

  .work_tabs li a.nav-link,
  .work_tabs li a.dealer-tab.nav-link.active {
    width: 225px !important;
    background-size: 225px !important;
  }

  .work_content .icon {
    width: 80px;
    height: 80px;
    font-size: 35px;
  }

  .work_image.sellers_img {
    max-width: 85%;
    position: relative;
    top: 50px;
    margin: 0px auto;
  }

  .work_content .work_text {
    font-size: 15px;
  }

  .work_image.dealers_img {
    max-width: 85%;
    position: relative;
    top: 0px;
    margin: 0px auto;
  }

  .otobix_points ul li .otobix_text {
    font-size: 15px;
    line-height: 22px;
  }

  /* .about_section {
    padding: 50px 0 50px;
  } */

  .about_section .about_container p {
    font-size: 16px !important;
    font-weight: 500;
  }

  .about_container p:nth-child(2) {
    font-size: 16px !important;
    line-height: 24px !;
  }

  .about_container p:nth-child(3) {
    font-size: 12px !important;
    line-height: 25px !important;
  }

  .otobix_button {
    padding: 7px 40px;
  }

  .test_icon {
    width: 50px;
    margin: 0px auto;
  }

  .testimonial_container p {
    font-size: 15px;
    line-height: 25px;
  }

  .text_testimonial span.icon,
  .test_desc_icon span.icon {
    width: 40px;
    height: 40px;
  }

  .text_testimonial p,
  .image_testimonial p {
    font-size: 14px;
    line-height: 21px;
  }

  .test_desc_icon {
    margin-bottom: 10px;
  }

  .testimonial_slider .row .col-lg-6:last-child,
  .testimonial_slider .row .col-lg-6:nth-last-child(2) {
    padding-top: 25px;
  }

  .testimonial_slider .owl-nav {
    margin-top: 25px;
  }

  .testimonial_slider .owl-nav button {
    width: 40px;
    height: 40px;
  }

  .testimonial_slider .owl-nav button span {
    font-size: 12px;
  }

  .faq_section:before {
    width: 35%;
    top: 74%;
    transform: translateY(-50%);
  }

  .accordion-button {
    font-size: 14px;
    line-height: 20px;
  }

  .accordion-body {
    font-size: 14px;
    line-height: 20px;
    padding: 18px 35px;
  }

  .accordion {
    margin-left: 360px;
    width: 70%;
  }

  .footer_section {
    padding: 60px 0 20px;
  }

  .ft_bottom_section p {
    font-size: 13px;
    letter-spacing: 0;
  }

  .footer_logo {
    width: 175px;
  }

  .footer_section .row h5 {
    font-size: 20px;
    line-height: 20px;
    margin-bottom: 20px;
  }

  .footer_menu ul li {
    margin-bottom: 20px;
  }

  .footer_menu ul li a {
    font-size: 15px;
  }

  .footer_contact ul li span.icon {
    width: 42px;
    height: 42px;
    line-height: 42px;
  }

  .footer_contact ul li {
    padding-left: 55px;
    min-height: 42px;
    line-height: 42px;
    font-size: 15px;
  }

  .footer_contact ul li.ft_address,
  .footer_contact ul li.ft_email {
    /* width: 255px; */
  }

  .footer_contact ul li.social_media a {
    width: 36px;
    height: 36px;
    line-height: 36px;
    margin-right: 6px;
    font-size: 12px;
  }

  .otobix_points ul li {
    padding-right: 10px;
  }

  .map-wrapper img {
    max-width: 90px;
  }

  .car-wrapper {
    top: -70px;
  }

  .sell-bye .nav-link {
    font-size: 18px;
  }

  .sell-bye .nav-link {
    padding: 12px 15px 11px;
  }

  .banner_form .form_input select {
    padding: 10px 10px 10px 40px;
    margin-bottom: 10px;
  }

  .banner_form {
    align-items: center;
    margin-top: 20px;
  }
}

@media only screen and (max-width: 1399px) {

  .work_tabs li a.nav-link,
  .work_tabs li a.dealer-tab.nav-link.active {
    width: 200px !important;
    background-size: 200px !important;
  }

  .work_tabs a {
    font-size: 15px;
    line-height: 28px;
  }

  .how_works_section .nav-tabs {
    padding-left: 42px;
  }
}

@media only screen and (max-width: 1300px) {
  .map-wrapper {
    top: -15px;
  }

  .car-wrapper {
    top: -15px;
  }
}

@media only screen and (max-width: 1199px) {
  .member-pic {
    max-width: 345px;
  }

  .our-team-info h3 span {
    font-size: 15px;
  }

  .our-team-info h3 {
    font-size: 22px;
    padding-top: 20px;
  }

  .our-team-sec:nth-child(odd)::before {
    border-left: 15px solid #f82f45;
  }

  .our-team-sec::before {
    border-right: 15px solid #f82f45;
    min-height: 60px !important;
  }

  .carrpic::before {
    width: 360px;
    height: 360px;
  }

  .abutdetl p {
    font-size: 14px;
    line-height: 27px;
  }

  .subtxthdng p {
    font-size: 14px;
    line-height: 28px;
  }

  .member-pic::before {
    max-width: 232px !important;
  }

  .our-team-info p {
    font-size: 14px;
    line-height: 27px;
  }

  .our-team-sec::before {
    min-height: 90px !important;
  }

  .our-team-sec:nth-child(even) .member-pic {
    margin-right: 50px;
  }

  .our-team-sec:nth-child(odd) .member-pic {
    margin-left: 50px;
  }

  .shpic img {
    max-width: 64%;
  }

  .shpic {
    top: 5%;
  }

  .Partners .container>.row {
    padding: 0 15px;
  }

  .Rcar {
    max-width: 32% !important;
    top: -14px;
  }
}

@media only screen and (max-width: 991px) {
  .member-pic {
    max-width: 250px;
    margin-top: -42px;
  }

  .member-pic::before {
    max-width: 130px !important;
  }

  .aboutsecond img {
    margin-bottom: 15px;
  }

  .subtxthdng p {
    font-size: 13px;
    line-height: 26px;
    margin-bottom: 34px;
  }

  .our-team-info h3 {
    font-size: 20px;
    padding-top: 20px;
  }

  .our-team-sec:nth-child(even) .member-pic {
    margin-right: 30px;
  }

  .our-team-sec:nth-child(odd) .member-pic {
    margin-left: 30px;
  }
}

@media only screen and (max-width: 575px) {
  .member-pic {
    max-width: 100%;
    margin-top: -10px;
    width: 100%;
    text-align: center;
  }

  .member-pic img {
    max-width: 230px !important;
  }

  .our-team-sec:nth-child(odd) h3 {
    text-align: center;
  }

  .our-team-info h3 {
    color: #000;
    margin-bottom: 10px;
    /* overflow: hidden; */
    text-align: center;
  }

  .our-team-info h3 span {
    font-size: 13px;
  }

  .our-team-sec::before {
    min-height: 50px !important;
  }

  .shpic {
    top: auto;
    bottom: 0;
  }

  .abutdetl p {
    font-size: 13px;
    line-height: 24px;
  }

  .our-team-info p {
    font-size: 13px;
    line-height: 24px;
  }

  .our-team-sec {
    position: relative;
    margin: 80px 0 70px;
  }

  .footer_contact ul li.ft_phone {
    flex: 0 0 100%;
  }
}

.thankyou-box {
  text-align: center;
  max-width: 512px;
  margin: 80px auto 34px;
  min-height: 80vh;
}

.thankyou-box h2 {
  font-family: "montserratbold";
  color: #000;
  font-style: normal;
  font-weight: 700;
  font-size: 46px;
  line-height: 48px;
  text-align: center;
  color: #000000;
}

.thankyou-box p {
  font-family: "montserratbold";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: #000000;
}

.btnbck {
  color: #fff;
  text-align: center;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  display: inline-block;
  background: #1c4075;
  border-radius: 30px;
  padding: 15px 25px;
  margin-top: 15px;
}

.btnbck:hover {
  background: #e02b3f;
  color: #fff;
}

.chkrund img {
  width: 120px;
  margin: 32px auto;
  display: inline-block;
  font-size: 95px;
  color: #1bd760;
}

.radio-inline {
  position: relative;
  display: inline-block;
  padding-left: 20px;
  margin-bottom: 0;
  font-weight: 400;
  vertical-align: middle;
  cursor: pointer;
  margin: 4px 10px 4px 3px;
}

.radio-inline input[type=radio] {
  position: absolute;
  margin-top: 4px 0 0;
  margin-left: -20px;
}


.dropdown {
  position: absolute;
  margin-left: 9em;
  /* margin-top: -35px; */
}

@media (max-width: 768px) {
  .dropdown {
    margin-left: 0;
    margin-top: 0;
    position: static;
  }
}

.city-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.modal-dialog-scrollable .modal-contents {
  max-height: 100%;
  overflow: hidden;
}

.modal-contents {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: var(--bs-modal-color);
  pointer-events: auto;
  background-color: var(--bs-modal-bg);
  background-clip: padding-box;
  border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
  border-radius: var(--bs-modal-border-radius);
  outline: 0;

}

.city-name {
  font-size: 12px;
  font-family: "Montserrat", sans-serif;
  color: #000;
  font-weight: 600;
}

.city-btn {
  font-size: 12px;
  /* font-weight: 800; */
  font-weight: 600;
  margin: 0 -10px;
  font-family: "Montserrat",
    sans-serif;
}

.img-hover-zoom--colorize {
  transition: transform 0.2s, filter 0.1s;
  cursor: pointer;
}

.img-hover-zoom--colorize img {
  transition: transform 0.2s, filter 0.1s;
  filter: inherit;
}

.img-hover-zoom--colorize:hover img {
  filter: none;
  /* Remove grayscale filter on hover */
  transform: scale(1.1);
  /* Zoom effect on hover */
}


.brand-line {
  width: 9rem;
  background-color: #f82f45;
  height: 5px;
}

.title {
  font-family: Montserrat;
  font-weight: 700;
  font-size: 26px;
}

.title .first {
  color: #f82f45;
}

.title .second {
  color: #193f76;
}

.tabs {
  box-shadow: #00000012 0 1px 2px, #00000012 0 2px 4px, #00000012 0 4px 8px, #00000012 0 8px 16px, #00000012 0 16px 32px, #00000012 0 32px 64px;
}

.p-3 {
  padding: 1rem !important;
}

.service-title {
  color: #000;
  font-family: Montserrat;
  font-weight: 700;
  font-size: 20px;
  padding: 10px;
}

.price1 {
  /* color: #007017; */
  color: #000;

}

@media only screen and (min-width: 1200px) {
  .detail-main {
    font-size: .9rem;
  }
}

.jss58 {
  fill: currentColor;
  width: .6em;
  display: inline-block;
  font-size: 24px;
  transition: fill .2s cubic-bezier(.4, 0, .2, 1) 0ms;
  -webkit-user-select: none;
  user-select: none;
  flex-shrink: 0;
}


.jss250 {
  color: green;
  font-size: 14px;
  margin-right: 5px;
}

.detail-main {
  padding: 10px;
  font-size: .9rem;
}

@media only screen and (min-width: 1200px) {

  .service-grid,
  .service-grid-three {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media only screen and (min-width: 992px) {

  .service-grid,
  .service-grid-three {
    grid-template-columns: 1fr 1fr;
  }
}

@media only screen and (min-width: 768px) {
  .service-grid {
    grid-template-columns: 1fr 1fr 1fr;
  }
}


@media only screen and (min-width: 600px) {

  .service-grid,
  .service-grid-three {
    grid-template-columns: 1fr 1fr;
  }
}

.service-grid,
.service-grid-three {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.service-padding {
  padding-top: 5px;
  padding-bottom: 5px;
}


.service-button {
  background-color: #193f76;
  border-color: #193f76;
  color: white;
}

.location-color {
  color: #080808;
  font-size: large;
}


.float {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 40px;
  right: 40px;
  background-color: #25d366;
  color: #FFF;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  box-shadow: 2px 2px 3px #999;
  z-index: 100;
  display: flex;
  justify-content: center;
  /* animation: zoomIn 1s infinite; */
  transition: transform 0.3s;
}

.my-float {
  margin-top: 16px;
}

.headerrw {
  justify-content: space-between;
  align-items: center;
}

/* @keyframes zoomIn {
    0%, 100% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.2);
    }
  } */

.float:hover {
  transform: scale(1.2);
}


.event-icon {
  position: absolute;
  width: auto;
  /* height: 50px; */
  padding: 8px;
  display: block;
  background: #1b4271;
  /* right: 20px; */
  /* top: -14px; */
  /* top: 56em; */
  bottom: 0;
  margin: auto;
  text-align: center;
  color: #fff;

  -webkit-border-radius: 8px;
  border-radius: 8px;

  /* padding: 2px 10px; */

}

.event-icon .month,
.event-icon .date {
  display: block;
}

.event-icon .month {
  text-transform: uppercase;
  /* font-weight: bold; */
  font-weight: 500;
  font-size: 1.1em;
  margin-top: 5px
}

.event-icon .date {
  font-size: 1.1em;
  /* font-weight: bold; */
  font-weight: 500;

}

.homebannerimg{
  width: 100%;
}


/* FOR RESPONSIVE ONLY  */
@media only screen and (max-width:480px) {
  .heading-title{
    width: 100%;
    margin: 0;
  }

  .heading-title h1 {
    font-size: 1em;
  }

  .title-event h2 {
    font-size: .9em;
  }

  .event-left-content .event-date,
  .event-left-content .event-location {
    font-size: .8em;
  }


}

/* _____________________________ */

.subscribe__copy {
  max-width: 450px;
  text-align: center;
  /* margin-bottom: 53px; */
  line-height: 1.5;
  margin: 0 auto 30px;
  font-family: "Montserrat", sans-serif;

  color: #000;
}

.form {
  margin-bottom: 25px;
      text-align: center;
        display: flex;
}

.form__email {
  padding: 20px 25px;
  border-radius: 5px;
  border: 1px solid #CAD3DB;
  /* width: 431px; */
  width: 100%;
  font-size: 18px;
  color: #0F121F;
}

.form__email:focus {
  outline: 1px solid #3D9FFF;
}

.form__button {
  background: #f82f45;
  padding: 10px;
  border: none;
  /* width: 244px; */
  width: 150px;
  height: 65px;
  border-radius:0 5px 5px 0;
  font-size: 18px;
  color: white;
  box-shadow: none;
  /* box-shadow: 0 4px 20px rgba(61, 159, 255, 0.7); */
}

/* .form__button:hover {
          box-shadow: 0 10px 20px rgba(61, 159, 255, 0.7);
        } */

.notice {
  width: 345px;
}


.subscription {
  display: flex;
  justify-content: center;
  align-items: center;
}