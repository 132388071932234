@media only screen and (max-width: 1920px) {
  .banner_section > img {
    width: 100%;
    height: 550px;
    object-fit: cover;
  }
  /* .footer_section {
    height: 407px;
  } */
  .BrandLogo {
    height: 467px;
  }
}

@media only screen and (max-width: 1800px) {
  .newbooking {
    max-width: 680px;
    width: 100%;
    flex: 0 0 680px;
  }
  .header_section .row .header_col2 {
    width: 59%;
  }
  .header_section .row .header_col3 {
    /* width: 29%; */
    width: auto;
  }

  .car-wrapper {
    max-width: 400px;
    top: -49%;
    left: -4%;
  }
  .car-wrapper img {
    max-width: 100%;
  }
  .stop1 {
    top: 2px;
  }
  .stop2 {
    top: 2px;
  }
  .stop3 {
    top: 2px;
  }
  .banner_section > img {
    height: 500px;
  }
}

@media only screen and (max-width: 1799px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1440px;
  }

  .header_menu ul.slimmenu li a {
    padding: 10px 9px;
    font-size: 13.5px;
  }

  .header_email .email_text,
  .header_phone .phone_text {
    font-size: 14px;
  }

  .banner_form {
    margin-top: 25px;
  }

  .teamtwo .container::before {
    width: 1470px;
    height: 895px;
  }

  .footer_menu ul li a {
    margin-left: 19px;
  }
  .map-wrapper::before {
    width: 53%;
  }
}
@media only screen and (max-width: 1700px) {
  .InsurancePartners ul li {
    width: 180px;
    height: 145px;
  }

  .LendingPartners ul li {
    width: 135px;
    height: 142px;
  }

  .Rcar {
    max-width: 38%;
    top: -74px;
  }

  .urgntrequire h3 {
    font-size: 53px;
  }

  .aprochpic {
    text-align: right;
  }

  .preownrpic {
    left: -11%;
    top: -49%;
  }

  .preownrpic img {
    max-width: 80%;
  }

  .preownr-detl {
    padding: 180px 0 255px 35px;
    position: relative;
    z-index: 1;
  }

  .footer_contact {
    max-width: 1160px;
  }
  .banner_section > img {
    height: 486px;
  }
}

@media only screen and (max-width: 1599px) {
  /* .footer_section {
    height: 358px;
  } */
  .car-wrapper {
    max-width: 350px;
    top: -61%;
    left: 1%;
  }
  .cararea {
    left: 39%;
  }
  .map-wrapper::before {
    width: 53%;
    left: 39%;
  }
  .kolarea {
    left: 39%;
  }
  .siliguriarea {
    /* left: 61%; */
     left: 63%;
  }
  .stop1 {
    left: 34%;
    padding: 10px 20px;
    top: -14px;
  }
  .stop2 {
    /* left: 53%; */
    left: 56%;
    padding: 10px 20px;
    top: -14px;
  }
  .stop3 {
    padding: 10px 20px;
    top: -14px;
  }
  .bhuvarea {
    left: 77%;
  }
  .insuadinfo h4 {
    font-size: 24px;
  }
  .insuadinfo {
    right: 4px;
  }
  .banner_section > img {
    height: 450px;
  }
}

@media only screen and (max-width: 1399px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1240px;
  }

  .header_section .container {
    max-width: 96% !important;
  }

  .header_menu ul.slimmenu li a {
    padding: 10px 6px;
    font-size: 12px;
  }
  .header_menu ul.slimmenu li .sub-toggle::before {
    width: 6px;
    height: 6px;
    border-left: 1px solid #1e1e1e;
    border-bottom: 1px solid #1e1e1e;
    margin-left: 14px;
  }
  .header_menu ul.slimmenu li .insurancenav {
    padding-left: 16px !important;
  }
  .header_menu ul.slimmenu li .carservicesnav {
    padding-left: 16px !important;
  }

  .header_email .email_text,
  .header_phone .phone_text {
    font-size: 12px;
  }

  .sell-bye .nav-link {
    font-size: 16px;
  }

  .banner_form .form_input .form_submit {
    /* padding: 5px 10px; */
  }

  .sellcarbtn .form_input .form_submit {
    /* padding: 0px 30px !important; */
  }
  .car-wrapper {
    max-width: 300px;
    top: -42%;
    left: 1%;
  }
  .stop1 {
    top: 5px;
    font-size: 14px;
    /* left: 34.5%; */
    left: 32.5%;

  }
  .stop2 {
    top: 5px;
    font-size: 14px;
    /* left: 55.5%; */
       left: 57.5%;
  }
  .stop3 {
    top: 5px;
    font-size: 14px;
  }
  .map-wrapper::before {
    width: 51%;
  }

  .Why-OtoBix {
    padding: 50px 0px 0px 0px;
  }
  .Why-OtoBix .work_heading h1 {
    font-size: 46px;
    margin-bottom: 60px;
  }
  .Why-OtoBix .work_heading h2 {
    font-size: 46px;
    margin-bottom: 60px;
  }

  .whyOtoBix p {
    font-size: 18px;
  }

  .BrandLogo .heading h2 {
    font-size: 46px;
  }

  .brand .item img {
    width: 160px !important;
    height: 160px;
  }

  .BrandLogo::after {
    width: 15%;
    height: 400px;
  }

  .brand .owl-prev span,
  .brand .owl-next span {
    font-size: 10px;
  }

  .brand .owl-prev,
  .brand .owl-next {
    width: 40px;
    height: 40px;
  }

  .work_image.dealers_img::before {
    width: 139%;
    height: 400px;
    top: -21%;
    left: -21%;
  }

  .work_image.sellers_img {
    top: -50px;
  }
  .how_works_section .work_heading h2 {
    font-size: 46px;
  }
  .displayText h4 {
    font-size: 50px;
  }

  .displayText .headingtxt {
    font-size: 50px;
  }

  /* .displayimg img {
    width: 100%;
  } */

  .click {
    padding: 10px 40px;
  }

  .cartext h6 {
    font-size: 45px;
    line-height: 55px;
  }
  .cartext .headingtxt {
    font-size: 45px;
    line-height: 55px;
  }
  .cartext {
    padding: 53px 20px;
  }

  .here {
    padding: 10px 40px;
  }

  .car2back {
    background-size: contain;
  }

  .about_container {
    margin: 0px 0 0 200px;
  }

  .about_image img {
    max-width: 80%;
  }

  .accordion {
    margin-left: 388px;
    width: 55%;
  }

  .fcar {
    max-width: 30%;
    height: auto;
    width: auto;
  }

  .flogo {
    /* width: 150px; */
    width: 216px;
    height: auto;
  }

  .footer_menu ul li a {
    font-size: 14px;
  }

  /* .footer_contact ul li.ft_address {
    width: 300px !important;
  }

  .footer_contact ul li.ft_phone {
    width: 260px !important;
  } */

  /******************about*************************/
  .bredcam ul li a,
  .bredcam ul li {
    font-size: 14px;
  }

  .LendingPartners h4,
  .InsurancePartners h4 {
    font-size: 40px;
  }

  .LendingPartners ul li {
    width: 128px;
    height: 128px;
  }

  .LendingPartners ul li img {
    width: 80px;
    height: auto;
  }

  .LendingPartners,
  .InsurancePartners {
    padding: 40px 10px 60px 10px;
  }

  .InsurancePartners ul li {
    width: 175px;
    height: 130px;
  }

  .InsurancePartners ul li img {
    width: 100px;
    height: auto;
  }

  .aboutTeamone h2 {
    font-size: 50px;
  }

  .founderimg img {
    width: 371px;
    height: 415px;
  }

  .founder-nane {
    width: 88%;
    left: 4%;
  }

  .aboutTeamone .row::before,
  .aboutTeamtwo .row::before {
    width: 350px;
    height: 460px;
  }

  .teamfst img,
  .teamsec img {
    width: 350px;
    height: 400px;
  }

  .teamfst .founder-nane,
  .teamsec .founder-nane {
    width: 57%;
  }

  .footertop {
    margin-bottom: 160px;
  }

  .founder-nane h6 {
    font-size: 16px;
  }

  .founder-nane p {
    font-size: 12px;
  }

  .teamtwo .container::before {
    width: 1300px;
    height: 860px;
  }

  /******************about************************/

  /******************about*************************/
  .bredcam ul li a,
  .bredcam ul li {
    font-size: 14px;
  }

  .LendingPartners h4,
  .InsurancePartners h4 {
    font-size: 40px;
  }

  .LendingPartners ul li {
    width: 130px;
    height: 130px;
  }

  .LendingPartners ul li img {
    width: 80px;
    height: auto;
  }

  .LendingPartners,
  .InsurancePartners {
    padding: 40px 10px 60px 10px;
  }

  .InsurancePartners ul li {
    width: 175px;
    height: 130px;
  }

  .InsurancePartners ul li img {
    width: 100px;
    height: auto;
  }

  .aboutTeamone h2 {
    font-size: 50px;
  }

  .founderimg img {
    width: 371px;
    height: 415px;
  }

  .founder-nane {
    width: 88%;
    left: 4%;
  }

  .aboutTeamone .row::before,
  .aboutTeamtwo .row::before {
    width: 350px;
    height: 460px;
  }

  .teamfst img,
  .teamsec img {
    width: 350px;
    height: 400px;
  }

  .teamfst .founder-nane,
  .teamsec .founder-nane {
    width: 57%;
  }

  .footertop {
    margin-bottom: 160px;
  }

  .founder-nane h6 {
    font-size: 16px;
  }

  .founder-nane p {
    font-size: 12px;
  }

  .teamtwo .container::before {
    width: 1300px;
    height: 860px;
  }

  /******************about************************/

  .urgntrequire h3 {
    font-size: 46px;
    line-height: 56px;
  }

  .preownrpic img {
    max-width: 72%;
  }

  .preownrpic {
    left: -6%;
    top: -30%;
  }

  .preowner-sec h3 {
    font-size: 50px;
  }

  .buy-sell-add > .container > .row {
    margin: 0 -25px;
  }

  .buy-sell-add > .container > .row > div {
    padding: 0 25px;
  }

  .uspfrst-add2 {
    top: 24px;
  }

  .uspfrst-add2 h5 {
    font-size: 52px;
  }

  .uspfrst-add h5 {
    font-size: 42px;
  }
  .footer_section {
    height: auto;
  }
  .footer_menu ul {
    padding-left: 0;
  }
  .required-ducument h2 {
    /* font-size: 48px; */
    line-height: 55px;
  }
  /*12-jan*/

  .preownr-uspbx-objct p {
    font-size: 14px;
    line-height: 23px;
  }
  .preownr-uspbx-objct h6 {
    max-width: 176px;
  }
  .citybg {
    text-align: right;
  }

  .citybg img {
    max-width: 87%;
  }

  .lookinfo h4 {
    font-size: 35px;
    line-height: 34px;
    margin-bottom: 18px;
  }
  .lookinfo p {
    font-size: 22px;
    line-height: 30px;
  }
  .lookdtl h4 {
    font-size: 45px;
  }
  .lookdtl h5 {
    font-size: 30px;
  }
  .lookdtl p {
    font-size: 18px;
  }

  .pretxt-add h5 {
    font-size: 34px;
  }
  .pretxt-add {
    max-width: 183px;
  }

  .stop1 {
    /* padding: 7px 16px 9px; */
    padding: 7px 24px 9px;

  }
  .stop2 {
    /* padding: 7px 16px 9px; */
    padding: 7px 21px 9px;

  }
  .stop3 {
    padding: 7px 16px 9px;
  }
  .insuadinfo h4 {
    font-size: 30px;
    line-height: 32px;
    margin-bottom: 8px;
  }
  .insuadinfo h5 {
    font-size: 38px;
  }
  .insuadinfo {
    max-width: 232px;
    top: 20px;
  }
  .polici-bx p {
    font-size: 16px;
  }
  .adtxtshw-rght h4 {
    font-size: 45px;
  }
  .adtxtshw-rght p {
    font-size: 15px;
    line-height: 25px;
  }
  .insuadinfo p {
    font-size: 14px;
    line-height: normal;
    margin-bottom: 12px;
  }

  .carservices-add .uspfrst-add2 h5 {
    font-size: 32px;
    line-height: 42px;
  }
  .carservices-add .uspfrst-add2 P {
    font-size: 18px;
  }
  .carservices-add .adredbg .lookdtl h3 {
    font-size: 38px;
  }
  .carservices-add .uspfrst-add2 {
    max-width: 275px;
  }
  .wrntycard span {
    width: 150px;
    height: 150px;
  }
  .wrntydtl ul li {
    font-size: 16px;
  }
  .rtoinfo p {
    font-size: 18px;
  }
  .asisdtl h4 {
    font-size: 50px;
    line-height: 60px;
  }
  .cnctlink p {
    font-size: 17px;
  }
  .inerfeturre-heading h2 {
    font-size: 59px;
  }
  .howtosrvc h3 {
    font-size: 50px;
  }
  .uspfeture-objctt h6 {
    font-size: 17px;
  }
  .infopdL {
    padding-left: 58px;
  }
  .banner_section > img {
    height: 392px;
    height: auto;
    width: auto;
  }
}

@media only screen and (max-width: 1300px) {
  .header_section .container {
    max-width: 96% !important;
  }
  .header_menu ul.slimmenu li a {
    padding: 10px 6px;
  }

  .map-wrapper {
    top: -30px;
  }

  .car-wrapper {
    top: -54px;
  }

  .newbooking h2 {
    font-size: 56px;
    line-height: normal;
  }

  .newbooking p {
    font-size: 17px;
  }

  .newbooking h3 {
    font-size: 46px;
    line-height: normal;
  }

  .quote-info p {
    font-size: 24px;
    line-height: 35px;
    max-width: 918px;
  }

  .susheding h3 {
    font-size: 54px;
  }

  .suspdibox p {
    font-size: 15px;
  }
  .invtrydealers {
    width: 100%;
    position: relative;
  }
  .banner_form .form_input input,
  .banner_form .form_input select {
    margin-bottom: 6px !important;
  }
  .bnrslct {
    margin-bottom: 7px;
  }
  .banner_form {
    margin-top: 15px;
  }
}

@media only screen and (max-width: 1199px) {
  .header_menu ul.slimmenu li a {
    font-size: 13px;
    padding: 6px 15px;
    text-align: left;
  }

  .header_email .email_text,
  .header_phone .phone_text {
    font-size: 13px;
    line-height: 18px;
  }

  .header_email span.icon,
  .header_phone span.icon {
    width: 30px;
    height: 30px;
  }

  .header_email span.icon:before,
  .header_phone span.icon:before {
    font-size: 35px;
  }

  .banner_content h1 {
    font-size: 55px;
  }

  .banner_form {
    margin-top: 30px;
  }

  .header_email {
    margin-right: 18px;
  }

  /* .site_logo {
    width: 160px;
  } */

  .header_section .row .header_col3 {
    /* width: 32%; */
    width: auto;
  }

  .header_section .row .header_col2 {
    width: 48%;
  }

  .banner_content h1 {
    font-size: 40px;
  }

  .work_heading h2,
  .otobix_container h2,
  .about_container h2,
  .testimonial_container h2,
  .faq_heading h2 {
    font-size: 35px;
    line-height: 45px;
  }

  .book_home {
    right: -165px;
    top: -180px;
  }

  .work_content .work_text {
    font-size: 14px;
  }

  .otobix_points ul li .otobix_text {
    font-size: 14px;
    line-height: 20px;
  }

  .about_container p:nth-child(2) {
    font-size: 16px;
    line-height: 22px;
  }

  /* .footer_contact ul li.ft_address,
  .footer_contact ul li.ft_email {
    width: 100%;
  } */

  .footer_contact ul li.ft_address {
    padding-top: 0px;
  }

  .ft_bottom_section {
    margin-top: 0px;
    padding-top: 20px;
  }

  .ft_bottom_section p {
    font-size: 14px;
  }

  .footer_section .row h5 {
    font-size: 18px;
  }

  .footer_logo {
    width: 150px;
  }

  .car-wrapper img {
    width: 250px;
  }

  .car-wrapper {
    top: -44px;
    left: 45px;
  }

  .map-wrapper img {
    width: 80px;
  }

  .map-wrapper:after {
    top: 54%;
  }

  .banner_content {
    top: 10px;
    right: 4%;
    width: 30%;
    padding: 10px 6px;
  }

  /* .sell-bye {
    width: 84%;
  } */

  .whyOtoBix p {
    font-size: 13px;
  }

  .whyOtoBix {
    min-height: 180px;
  }

  .BrandLogo .heading h2 {
    font-size: 40px;
  }

  .brand .item img {
    width: 140px !important;
    height: 140px;
  }

  .cartext {
    padding: 16px 20px;
  }

  .cartext h6 {
    font-size: 40px;
    line-height: 60px;
  }

  .cartext .headingtxt {
    font-size: 40px;
    line-height: 60px;
  }
  .displayText h4 {
    font-size: 40px;
  }
  .displayText .headingtxt {
    font-size: 40px;
  }
  .click,
  .here {
    padding: 7px 30px;
  }

  .displayimg img {
    width: 90%;
    margin-left: 10%;
  }

  .about_container {
    margin: 0px 0 0 50px;
  }

  .about_container p:nth-child(2) {
    font-size: 13px;
    line-height: 20px;
  }

  .about_image img {
    top: -90px;
    max-width: 90%;
  }

  .faq_section:before {
    width: 50%;
    top: 72%;
    transform: translateY(-50%);
  }

  /* .footer_contact ul li.ft_phone {
    width: 370px;
  } */

  .footer_menu ul {
    flex-wrap: wrap;
  }

  .banner_form {
    margin-top: 8px !important;
  }

  .banner_form .form_input input,
  .banner_form .form_input select {
    line-height: 0px !important;
    margin-bottom: 2px !important;
  }

  /******************about************************/
  .subbannertext h2,
  .subbannertext .bluee {
    font-size: 48px;
  }

  .AboutDemo p,
  .aboutcarText p,
  .foundertext p,
  .teamftext p,
  .teamstext p {
    font-size: 12px;
  }

  .aboutsecond {
    padding: 35px 0px;
  }

  .LendingPartners h4,
  .InsurancePartners h4 {
    font-size: 36px;
  }

  .LendingPartners ul li {
    width: 100px;
    height: 100px;
  }

  .LendingPartners ul li {
    width: 100px;
    height: 100px;
  }

  .LendingPartners ul li img {
    width: 60px;
    height: auto;
  }

  .InsurancePartners ul li {
    width: 140px;
    height: 100px;
  }

  .InsurancePartners ul li img {
    width: 75px;
    height: auto;
  }

  .Partners,
  .AboutDemo {
    padding: 35px 0px;
  }

  .founderimg img {
    width: 315px;
    height: 365px;
  }

  .aboutTeamone .row::before,
  .aboutTeamtwo .row::before {
    width: 260px;
    height: 400px;
  }

  .founder-nane {
    width: 91%;
    left: 4%;
    bottom: 12%;
  }

  .teamfst img,
  .teamsec img {
    width: 290px;
    height: 400px;
  }

  .teamftext p,
  .teamstext p {
    line-height: 24px;
    margin: 15px 0;
  }

  .aboutTeamtwo .founderimg img {
    margin-top: 40px;
  }

  .aboutTeamone .founderimg img {
    margin-top: 65px;
  }

  .footertop {
    margin-bottom: 100px;
  }

  .aboutTeamone .row::before {
    top: 23%;
  }

  .aboutTeamone {
    padding: 10px 0px;
  }

  .teamtwo .container::before {
    display: none;
  }

  /******************about************************/

  /******************about************************/
  .subbannertext h2,
  .subbannertext .bluee {
    font-size: 48px;
  }

  .AboutDemo p,
  .aboutcarText p,
  .foundertext p,
  .teamftext p,
  .teamstext p {
    font-size: 12px;
  }

  .aboutsecond {
    padding: 35px 0px;
  }

  .LendingPartners h4,
  .InsurancePartners h4 {
    font-size: 36px;
  }

  .LendingPartners ul li {
    width: 100px;
    height: 100px;
  }

  .LendingPartners ul li {
    width: 100px;
    height: 100px;
  }

  .LendingPartners ul li img {
    width: 60px;
    height: auto;
  }

  .InsurancePartners ul li {
    width: 140px;
    height: 100px;
  }

  .InsurancePartners ul li img {
    width: 75px;
    height: auto;
  }

  .Partners,
  .AboutDemo {
    padding: 35px 0px;
  }

  .founderimg img {
    width: 315px;
    height: 365px;
  }

  .aboutTeamone .row::before,
  .aboutTeamtwo .row::before {
    width: 260px;
    height: 400px;
  }

  .founder-nane {
    width: 91%;
    left: 4%;
    bottom: 12%;
  }

  .teamfst img,
  .teamsec img {
    width: 290px;
    height: 400px;
  }

  .teamftext p,
  .teamstext p {
    line-height: 24px;
    margin: 15px 0;
  }

  .aboutTeamtwo .founderimg img {
    margin-top: 40px;
  }

  .aboutTeamone .founderimg img {
    margin-top: 65px;
  }

  .footertop {
    margin-bottom: 100px;
  }

  .aboutTeamone .row::before {
    top: 23%;
  }

  .aboutTeamone {
    padding: 10px 0px 50px;
  }

  .teamtwo .container::before {
    display: none;
  }

  .shpic img {
    max-width: 64%;
  }

  .shpic {
    top: 5%;
  }

  .Partners .container > .row {
    padding: 0 15px;
  }

  .Rcar {
    max-width: 32% !important;
    top: -14px;
  }

  .suspendisse-sec .row {
    margin: 0;
  }

  .newbooking {
    max-width: 598px;
    width: 100%;
    flex: 0 0 598px;
  }

  /******************about************************/

  .urgntrequire h3 {
    font-size: 42px;
    line-height: 51px;
  }

  .looking-option .container > .row > div {
    padding: 0 15px;
  }

  .looking-option .container > .row {
    margin: 0 -15px;
  }

  .lookinfo h4 {
    font-size: 38px;
    line-height: 40px;
  }

  .lookdtl h4 {
    font-size: 40px;
  }

  .lookdtl h5 {
    font-size: 27px;
  }

  .lookdtl p {
    font-size: 18px;
    line-height: 22px;
  }

  .lookinfo p {
    font-size: 18px;
    line-height: 22px;
  }

  .lookdtl span img {
    max-width: 75%;
  }

  .lookinfo span img {
    max-width: 75%;
  }

  .preownrpic img {
    max-width: 66%;
  }

  .preownrpic {
    left: -1%;
    top: -28%;
  }

  .preownr-detl {
    padding: 200px 0 255px 35px;
  }

  .preowner-sec h3 {
    font-size: 45px;
  }

  .preowner-sec p {
    font-size: 17px;
    line-height: 28px;
  }

  .btompading {
    padding-bottom: 15px;
    overflow: hidden;
  }

  .pretxt-add h5 {
    font-size: 28px;
  }

  .pretxt-add {
    max-width: 148px;
  }
  .pretxt-add P {
    font-size: 13px;
  }
  .pretxt-add img {
    max-width: 80%;
  }
  .presell span img {
    width: auto;
    max-width: 118px;
  }
  .banner_section > img {
    height: 343px;
  }
}

@media only screen and (max-width: 1024px) {
  .banner_section > img {
    height: 288px;
  }
  .header_section .row .header_col3 {
    width: 34%;
  }
  .header_menu ul.slimmenu li a {
    padding: 6px 12px;
  }
  .aboutcarText p {
    line-height: 25px;
  }
  .car-wrapper img {
    width: 220px;
  }
  .car-wrapper {
    top: -30px;
    left: 37px;
  }
  .bhuvarea {
    left: 75.4%;
  }
  .cartext h6 {
    font-size: 30px;
    line-height: normal;
  }
  .cartext .headingtxt {
    font-size: 30px;
    line-height: normal;
  }
  .displayText {
    z-index: 1;
  }
  .stop1 {
    padding: 5px 14px 7px;
    font-size: 12px;
  }
  .stop2 {
    padding: 5px 14px 7px;
    font-size: 12px;
  }
  .stop3 {
    padding: 5px 14px 7px;
    font-size: 12px;
  }
  .map-wrapper img {
    width: 75px;
  }
}

@media only screen and (max-width: 991px) {
  .header_menu ul.slimmenu li a {
    padding: 6px 10px;
  }
  .header_menu ul.slimmenu li .carservicesnav,
  .header_menu ul.slimmenu li .insurancenav {
    padding-left: 10px !important;
  }

  .banner_content h1 {
    font-size: 40px;
  }

  .banner_form {
    margin-top: 20px;
  }

  .banner_content {
    top: 20px;
    right: 1%;
  }
  .stop1 {
    padding: 6px 12px 6px;
    font-size: 12px;
  }
  .stop2 {
    padding: 6px 12px 6px;
    font-size: 12px;
  }
  .stop3 {
    padding: 6px 12px 6px;
    font-size: 12px;
  }

  /* .menu-collapser {
    padding: 0;
  } */
.menu-collapser {
	padding: 0;
	position: absolute;
	right: 75px;
	width: auto;
	top: 0;
}
  .email_text,
  .phone_text {
    display: none;
  }

  .header_email span.icon,
  .header_phone span.icon {
    margin-right: 0;
  }

  .header_email {
    margin-right: 10px;
  }

  .header_section .row .header_col3 {
    width: 15%;
  }

  .header_section .row .header_col2 {
    width: 65%;
  }

  /*.collapse-button{display: table;width: 30px;height: 30px;border-radius: 100%;padding: 7px;}*/

  /* .header_section .row div:nth-child(3) {
    position: absolute;
    right: 75px;
  } */

  .header_section .row div:nth-child(3) {
	position: absolute;
	right: 75px;
	width: 100% !important;
	left: 0;
}

  .header_section {
    padding: 0;
  }

  .header_section .container {
    padding: 0;
    width: 100%;
    max-width: 100%;
  }

  .site_logo {
    margin: 10px 12px;
  }

  .header_section .row {
    position: relative;
    margin: 0px;
    align-items: center;
    justify-content: space-between;
  }

  .header_section .row > div {
    width: auto !important;
    padding: 0;
  }

  
  .dropdown {
  position: absolute;
  margin-left: 9em;
  left: 130px;
  /* margin-top: -35px; */
}
  /* .collapse-button {
    position: absolute;
    right: 12px;
    top: 50%;
    width: 40px;
    padding: 7px 10px;
    color: #ffffff;
    font-size: 14px;
    text-align: center;
    background-color: #0e0e0e;
    border-radius: 2px;
    cursor: pointer;
    transform: translate(0, -50%);
    box-sizing: border-box;
  } */
.collapse-button {
	background-color: #0e0e0e;
	border-radius: 2px;
	box-sizing: border-box;
	color: #fff;
	cursor: pointer;
	font-size: 14px;
	padding: 7px 10px;
	position: absolute;
	right: 12px;
	text-align: center;
	top: 7%;
	transform: translateY(-50%);
	width: 40px;
}
  ul.slimmenu {
    position: absolute;
    /* top: 100%; */
     top: 30px;
    left: 0;
    width: 100%;
    z-index: 999;
    background: #ffffff;
    display: none;
  }

  .banner_form .submitt {
    width: 100% !important;
    display: flex;
    justify-content: center;
  }

  .header_menu ul.slimmenu li .sub-toggle::before {
    margin: -4px 0 0 0;
  }

  .header_menu ul.slimmenu li .sub-toggle {
    top: 0;
    right: 0;
    width: 34px;
    height: 34px;
    transform: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .banner_section > img {
    background-size: cover;
    height: 350px;
  }

  .banner_form {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    /* margin-right: -5px;
    margin-left: -5px; */
  }

  .banner_form .form_input {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.3333%;
    max-width: 100%;
    position: relative;
    padding-right: 5px;
    padding-left: 5px;
    margin-top: 5px;
    margin-right: 0;
  }

  .work_heading h2,
  .otobix_container h2,
  .about_container h2,
  .testimonial_container h2,
  .faq_heading h2 {
    font-size: 32px;
    line-height: 42px;
  }

  .work_content .icon {
    width: 65px;
    height: 66px;
    font-size: 25px;
  }

  .book_home {
    right: -85px;
    top: -180px;
  }

  .work_points_left,
  .work_points_right {
    width: 175px;
  }

  .work_content .work_num {
    right: 35px;
  }

  .hassle_free {
    bottom: -75px;
  }

  .footer_logo {
    width: 150px;
    margin: 0px auto 30px;
  }

  .footer_section {
    padding: 40px 0 20px;
  }

  .otobix_section .row .otobix_col:first-child {
    padding-top: 40px;
  }

  .otobix_container h2 {
    text-align: center;
  }

  .otobix_images img:first-child {
    clip-path: inherit;
  }

  .about_container p:nth-child(2) {
    font-size: 15px;
    line-height: 20px;
  }

  .testimonial_container {
    width: 100%;
    text-align: center;
  }

  .image_testimonial .test_image {
    width: 30%;
    margin-right: 20px;
  }

  .otobix_images img:last-child {
    display: none;
  }

  .map-wrapper img {
    width: 50px;
  }

  .car-wrapper img {
    width: 190px;
  }

  .sell-bye .nav-link {
    font-size: 14px;
  }

  /* .banner_form .form_input.submit_input {
    width: 50%;
    float: right;
  } */

  .car-wrapper {
    top: -65px;
  }

  .map-wrapper {
    top: -49px;
  }
  /* .map-wrapper::before {
    top: 100%;
  }
  .cararea {
    top: 100%;
  }
  .kolarea {
    left: 39%;
    top: 91%;
  }
  .siliguriarea{
    top: 91%;
  } */

  .brand .item img {
    width: 140px;
    height: 140px;
  }

  .work_image.dealers_img::before {
    left: -50%;
  }

  .cartext h6 {
    width: 35%;
  }
  .cartext .headingtxt {
    width: 35%;
  }
  .cartext {
    padding: 120px 20px;
    margin-top: 20px;
  }

  .carDisplay {
    padding: 30px 10px;
  }

  .about_container {
    margin: 0px 0 0 0px !important;
  }

  .about_container p:nth-child(2) {
    font-size: 12px;
  }

  .about_image img {
    max-width: 100%;
  }

  .faq_section:before {
    display: none;
  }

  .accordion {
    margin-left: 70px;
    width: 80%;
  }

  .footer_contact .faddress {
    flex-wrap: wrap;
  }

  .footer_contact ul li {
    margin-bottom: 10px;
    font-size: 14px;
  }

  .fsocial {
    padding: 30px 10px 30px 10px;
  }
  .fsocial {
    max-width: 200px;
    text-align: center;
  }

  .layout-expanded2 .headercontainer .navigation .navigationbox {
    margin-left: 0px;
  }

  .layout-expanded2 {
    overflow: hidden;
  }

  .layoutovarlay2 {
    position: fixed;
    z-index: 1000;
    top: 0;
    right: 0;
    height: 100% !important;
    left: 0;
    display: none;
    background: #181c21;
    opacity: 0.5;
    cursor: pointer;
  }

  .layout-expanded2 .layoutovarlay2 {
    display: block;
  }

  .headercontainer .navigationouter {
    padding: 0px;
    border: none;
  }

  /******************about************************/
  .aboutcarText p {
    line-height: 22px;
  }

  .InsurancePartners ul li {
    width: 130px;
    height: 100px;
  }

  .LendingPartners,
  .InsurancePartners {
    padding: 40px 10px 30px 10px;
  }

  .aboutTeamone .row::before,
  .aboutTeamtwo .row::before {
    display: none;
  }

  .aboutTeamone .founderimg img {
    margin-top: 0px;
  }

  .foundertext p {
    line-height: 24.5px;
  }

  .founder-nane {
    width: 94%;
  }

  .teamfst img,
  .teamsec img {
    width: 310px;
    height: 350px;
  }

  .teamfst .founder-nane,
  .teamsec .founder-nane {
    width: 64%;
  }

  .teamftext p,
  .teamstext p {
    margin: 10px 0;
  }

  .teamtwo {
    padding: 40px 0px;
  }

  .aboutTeamtwo .founderimg img {
    margin-top: 0px;
  }

  .founderimg img {
    width: 100%;
  }

  .founder-nane {
    bottom: 4%;
  }

  .teamfst img,
  .teamsec img {
    width: 100%;
    height: auto;
  }

  .teamfst .founder-nane,
  .teamsec .founder-nane {
    width: 98%;
  }

  /******************about************************/
  /******************about************************/
  .aboutcarText p {
    line-height: 22px;
  }

  .InsurancePartners ul li {
    width: 130px;
    height: 100px;
  }

  .LendingPartners,
  .InsurancePartners {
    padding: 40px 10px 30px 10px;
  }

  .aboutTeamone .row::before,
  .aboutTeamtwo .row::before {
    display: none;
  }

  .aboutTeamone .founderimg img {
    margin-top: 0px;
  }

  .foundertext p {
    line-height: 24.5px;
  }

  .founder-nane {
    width: 94%;
  }

  .teamfst img,
  .teamsec img {
    width: 310px;
    height: 350px;
  }

  .teamfst .founder-nane,
  .teamsec .founder-nane {
    width: 64%;
  }

  .teamftext p,
  .teamstext p {
    margin: 10px 0;
  }

  .teamtwo {
    padding: 40px 0px;
  }

  .aboutTeamtwo .founderimg img {
    margin-top: 0px;
  }

  .founderimg img {
    width: 100%;
  }

  .founder-nane {
    bottom: 4%;
  }

  .teamfst img,
  .teamsec img {
    width: 100%;
    height: auto;
  }

  .teamfst .founder-nane,
  .teamsec .founder-nane {
    width: 98%;
  }

  /******************about************************/

  .bkfrom select {
    height: 46px;
    font-size: 14px;
  }

  .bkfrom input {
    height: 46px;
    font-size: 14px;
  }

  .newbooking h3 {
    font-size: 40px;
  }

  .newbooking p {
    font-size: 16px;
  }

  .newbooking h2 {
    font-size: 50px;
  }

  .quote-info p {
    font-size: 20px;
    line-height: 30px;
    max-width: 700px;
  }

  .susheding h3 {
    font-size: 49px;
  }

  .suspdibox h4 {
    font-size: 18px;
  }

  .suspdibox p {
    font-size: 14px;
    line-height: 20px;
  }

  .ctgrypic img {
    max-width: 60px;
  }

  .suspic span svg {
    max-width: 100px;
  }

  .urgntrequire .aprochpic {
    position: relative;
    text-align: center;
    margin-top: 30px;
  }

  .urgntrequire {
    margin: 40px 0 0;
    position: relative;
  }

  .urgntrequire h3 {
    font-size: 36px;
    line-height: 49px;
  }

  .preownr-detl {
    padding: 50px 0 !important;
  }

  .preownrpic {
    left: 0 !important;
    top: 0;
    position: relative;
    margin-top: -30%;
  }

  .preownrpic img {
    max-width: 100% !important;
  }

  .preowner-sec {
    padding: 0 0 50px;
  }

  .loking-loan {
    position: relative;
    margin-bottom: 20px;
  }

  .urgntrequire img {
    max-width: 100%;
  }

  .buy-sell-add > .container > .row {
    margin: 0 -15px;
  }

  .buy-sell-add > .container > .row > div {
    padding: 0 15px;
  }

  .finanabut {
    position: relative;
    max-width: 540px;
    margin: 30px auto 0;
  }

  .inerfeturre-heading h2 {
    font-size: 40px;
    line-height: 50px;
  }

  .ordrlst {
    order: 3;
  }

  .usebtompd {
    padding-bottom: 400px;
  }

  .required-ducument h2 {
    font-size: 40px;
    line-height: 50px;
  }

  .requiredoc {
    display: block;
  }

  .ducumntbx {
    margin-bottom: 20px;
  }

  .pretxt-add h5 {
    font-size: 22px;
  }
  .presell h5 {
    font-size: 30px;
  }
  .presell h4 {
    font-size: 50px;
  }
  .presell p {
    font-size: 16px;
    line-height: normal;
  }
  .pretxt-add {
    top: 50%;
    transform: translateY(-50%);
  }
  .preadvrtise {
    margin: 8px 0;
  }
  .fetureusp-info h2 {
    font-size: 40px;
    line-height: normal;
  }
  .urgntrequire h3 {
    font-size: 40px;
    line-height: normal;
  }

  .major-info {
    display: grid;
  }
  .majorinfo-dtl {
    margin: 0 auto;
  }
  .iconusp {
    position: relative;
    width: 100px;
  }
  .iconusp svg {
    width: 100px;
  }
  .loan-from h2 {
    font-size: 45px;
  }
  .wrnty-list {
    display: grid;
    max-width: 400px;
    margin: 0 auto;
  }
  .wrntydtl {
    order: 1;
  }
  .wrntycard {
    order: 2;
  }
  .wrntydtl ul {
    padding: 20px 0 0;
    margin: 0;
  }
  .warrenty-info {
    padding-bottom: 130px;
    background-size: cover;
  }
  .cnctlink p {
    font-size: 17px;
    line-height: normal;
  }
  .banner_content {
    width: 31%;
  }
  .dsplygap {
    padding: 0 5px;
  }
}

@media only screen and (max-width: 767px) {
  .suspendisse-sec .row > div {
    width: 50%;
    flex: 0 0 50%;
  }

  .buynew-inner {
    display: block;
  }

  .newbooking h2 {
    font-size: 42px;
  }

  .newbooking h3 {
    font-size: 32px;
  }

  .newbooking p::after {
    width: 86px;
    height: 5px;
  }

  .newbuyclb {
    margin-top: 50px;
  }

  .preownrusp-sec .container .row .col {
    flex: 1 0 100%;
  }

  .dtlspic {
    height: 340px;
  }
  #thumbs .item {
    height: 65px;
    margin: 0 8px;
  }
  .reviewcontainer .reviewouter .reviewstp .reviewstpheading {
    font-size: 34px;
  }
  .inner-banner-sec {
    margin-bottom: 28px;
  }

  .dtlspic {
    height: 340px;
  }
  #thumbs .item {
    height: 65px;
    margin: 0 8px;
  }
  .reviewcontainer .reviewouter .reviewstp .reviewstpheading {
    font-size: 34px;
  }
  .inner-banner-sec {
    margin-bottom: 28px;
  }

  .inerfeturre-heading h2 {
    font-size: 30px;
    letter-spacing: normal;
    line-height: normal;
  }
  .howtosrvc {
    padding-left: 0;
  }
  .howtosrvc h3 {
    font-size: 30px;
    line-height: normal;
  }
  .howtosrvc ul li span {
    width: 22px;
    height: 22px;
    font-size: 22px;
    top: 5px;
  }
  .carservices-add .adredbg .lookdtl h3 {
    font-size: 24px;
    line-height: normal;
  }
  .carservices-add .uspfrst-add2 h5 {
    font-size: 25px;
    line-height: normal;
  }

  .botom-assitence {
    display: grid;
  }
  .asistpic {
    text-align: center;
  }
  .asisdtl {
    padding-left: 0;
    text-align: center;
  }
  .cnctlink {
    justify-content: center;
  }
  .asisdtl h4 {
    font-size: 40px;
    line-height: normal;
    text-align: center;
  }
  .banner_form .form_input span {
    top: 48%;
    transform: translateY(-50%);
  }
}

@media only screen and (max-width: 769px) {
  .work_heading h2,
  .otobix_container h2,
  .about_container h2,
  .testimonial_container h2,
  .faq_heading h2 {
    font-size: 25px;
    line-height: 32px;
  }

  .accordion-body {
    font-size: 14px;
    line-height: 20px;
    padding: 15px 25px;
  }

  .accordion-button {
    font-size: 15px;
    line-height: 20px;
    padding: 10px 60px 10px 15px;
  }

  .accordion {
    width: 80%;
  }

  .faq_section:before,
  .faq_section:after {
    display: none;
  }

  .footer_section .row h5 {
    font-size: 16px;
  }

  .banner_content h1 {
    font-size: 35px;
  }

  .how_works_section {
    overflow: hidden;
  }

  .tab-content .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .tab-content .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .work_points_left,
  .work_points_right {
    width: 125px;
  }

  .work_content .work_num {
    right: 20px;
  }

  .work_content .work_text {
    font-size: 13px;
    line-height: 13px;
  }

  .enter_details {
    top: 100px;
  }

  .get_offers {
    left: -100px;
  }

  .hassle_free {
    bottom: -40px;
    left: -130px;
  }

  .how_works_section {
    padding: 35px 0 80px;
  }

  .testimonial_slider .owl-nav {
    width: 100%;
  }

  .testicon_none {
    height: 0px;
  }

  .ft_bottom_section p {
    font-size: 13px;
  }

  .otobix_points {
    margin-right: 0px;
  }

  .map-wrapper img {
    width: 40px;
  }

  .car-wrapper img {
    width: 150px;
  }

  .car-wrapper {
    top: -20px;
  }

  .desktop-banner {
    display: none;
  }

  .mobile-banner {
    display: block;
  }

  .banner_form .form_input input,
  .banner_form .form_input select {
    line-height: 15px;
  }

  .banner_form .form_input select {
    padding: 10px 15px 8px 40px;
  }

  .car-wrapper {
    top: -60px;
  }

  /* .sell-bye .nav-link {
    font-size: 17px;
  } */

  .sell-bye .nav-link {
    padding: 12px 15px 9px;
  }

  .banner_content {
    top: 3%;
    width: 50%;
  }

  .banner_form .form_input .form_submit {
    /* padding: 7px 10px; */
  }

  .banner_form .form_input.submit_input {
    float: left;
  }

  .map-wrapper {
    width: 95%;
  }

  .map-wrapper:before {
    left: 22%;
    width: 75%;
  }

  .cartext {
    padding: 75px 20px;
  }

  /******************about************************/
  .LendingPartners h4,
  .InsurancePartners h4 {
    font-size: 28px;
  }

  .InsurancePartners ul li {
    width: 150px;
  }

  .InsurancePartners ul li img {
    width: 80px;
  }

  /******************about************************/

  /*12-jan*/
  .preownrusp-sec .container .row .col {
    flex: 1 0 100%;
  }
  .preownr-uspbx-objct {
    min-height: auto;
  }
  .preownr-uspbx {
    display: flex;
    flex-wrap: wrap;
  }
  .shapebox {
    align-items: start;
    order: 1;
    min-height: auto;
  }
  .midle-line {
    order: 3;
  }
  .preownr-uspbx-objct {
    order: 2;
  }
  .adredbg .lookdtl h3 {
    font-size: 30px;
    line-height: 38px;
  }
  .lookdtl span img {
    min-height: auto;
  }
  .adredbg {
    max-width: 202px;
  }
  .preownrbgnon {
    padding: 60px 0 200px;
    background-repeat: no-repeat !important;
    background-size: cover;
  }
  .otobxemply {
    position: relative;
    left: 0;
    bottom: 30px;
  }
  .iconbx {
    width: 90px;
    height: 90px;
  }
  .iconbx img {
    max-width: 60px;
  }
  .iconbx:nth-child(2) {
    width: 120px;
    height: 120px;
    margin-top: 0;
  }
  .citybg {
    top: 50%;
  }
  .urgntrequire p {
    font-size: 15px;
    line-height: 24px;
  }
  .roadpic {
    margin-top: -60px;
  }

  /*2 feb*/
  .finacial-achive h6 {
    font-size: 20px;
  }
  .finacial-achive h3 {
    font-size: 30px;
    margin-top: 20px;
  }
  .wlth-detls p {
    font-size: 14px;
    line-height: 23px;
  }
  .wlth-detls h6 {
    font-size: 18px;
  }
  .shapeicon {
    max-width: 110px;
  }
  .shapeicon span svg {
    max-width: 110px;
  }
  .shapeicon img {
    max-width: 60%;
  }
  .intrested-link h5 {
    font-size: 35px;
  }
  .intrested-link img {
    margin-top: -60px;
    max-width: 66px;
  }
  .intrested-link {
    gap: 20px;
  }
  .intrested-link a {
    font-size: 15px;
    padding: 10px 25px 9px;
  }
  .welthbody-sec {
    background: #f2fcff;
    padding: 30px 0 10px;
    margin-bottom: 30px;
  }
  .bredcam ul li {
    margin-right: 20px;
  }
  .bredcam ul li a::before {
    right: -11px;
    top: 5px;
  }
  .welth-info {
    margin-bottom: 20px;
  }
  .looking-option {
    overflow: hidden;
  }
  .preownrpic {
    position: static;
  }
  .has-submenu .sub-menu {
    width: 100% !important;
  }
  .wrntycard {
    width: 250px;
    height: 250px;
    flex: 0 0 250px;
    margin: 0 auto;
  }
}

@media only screen and (max-width: 575px) {
  .banner_section img {
    object-fit: cover;
  }

  .work_content .icon {
    width: 50px;
    height: 50px;
    font-size: 20px;
  }

  .book_home {
    right: 0px;
    top: -150px;
  }

  .get_offers {
    top: -75px;
    left: -50px;
  }

  .work_content .work_num {
    font-size: 11px;
    width: 25px;
    height: 25px;
  }

  .work_points_left,
  .work_points_right {
    width: 100px;
  }

  .work_content .work_num {
    right: 10px;
  }

  .enter_details {
    top: 80px;
    left: 0px;
  }

  .instant_transact {
    top: 0;
    right: 40px;
  }

  .dropdown {
  position: absolute;
  margin-right: 9em;
  /* left: 152px; */
  left: 10px;
  /* margin-top: -35px; */
}

  .hassle_free {
    bottom: 0px;
    left: -178px;
  }

  .how_works_section {
    padding: 35px 0 40px;
  }

  .work_tabs li a.nav-link,
  .work_tabs li a.dealer-tab.nav-link.active {
    width: 150px !important;
    background-size: 150px !important;
  }

  .work_tabs a {
    font-size: 12px;
    line-height: 17px;
  }

  .otobix_points ul li .otobix_text {
    font-size: 13px;
    line-height: 20px;
  }

  .otobix_points ul {
    display: inherit;
  }

  .otobix_points ul li {
    width: 100%;
    padding: 12px 0px;
  }

  .banner_content {
    top: 3%;
    width: 80%;
    right: 10%;
    background: #ffffff5c;
  }

  .whyOtoBix p {
    font-size: 20px;
  }

  .BrandLogo::after {
    width: 20%;
    height: 265px;
    top: 95%;
  }

  .contactBtn {
    padding: 10px 30px;
  }

  .contact-Btn {
    text-align: center !important;
    margin-top: 30px;
  }

  .cartext {
    padding: 40px 20px;
  }

  .cartext h6 {
    width: 49%;
  }
  .cartext .headingtxt {
    width: 49%;
  }
  .about_image img {
    max-width: 40%;
  }

  .accordion-body {
    font-size: 12px;
  }

  .accordion-button {
    font-size: 12px;
  }

  .banner_form .mark {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
    background-color: transparent;
  }

  /******************about************************/
  .subbannertext h2,
  .subbannertext .bluee {
    font-size: 28px;
  }

  .aboutsecond img {
    margin-bottom: 20px;
  }

  .Partners,
  .AboutDemo {
    padding: 0px 0px;
  }

  .LendingPartners h4,
  .InsurancePartners h4 {
    font-size: 36px;
  }

  .LendingPartners ul li {
    width: 120px;
    height: 120px;
  }

  .LendingPartners ul li img {
    width: 80px;
    height: auto;
  }

  .InsurancePartners ul li {
    width: 160px;
    height: 120px;
  }

  .InsurancePartners ul li img {
    width: 90px;
  }

  .LendingPartners,
  .InsurancePartners {
    margin-bottom: 20px;
  }

  .aboutTeamone h2 {
    font-size: 40px;
  }

  .founder-nane {
    bottom: 7%;
    left: 3%;
  }

  .founderimg img {
    margin-bottom: 20px;
  }

  /******************about************************/

  .shpic {
    top: auto;
    bottom: 0;
  }

  .abutdetl p {
    font-size: 13px;
    line-height: 24px;
  }

  .our-team-info p {
    font-size: 13px;
    line-height: 24px;
  }

  .our-team-sec {
    position: relative;
    margin: 80px 0 70px;
  }

  .susheding h3 {
    font-size: 35px;
    line-height: 38px;
  }

  .quote-info p {
    font-size: 15px;
    line-height: 25px;
  }

  .urgntrequire h3 {
    font-size: 30px;
  }

  .preowner-sec h3 {
    font-size: 38px;
  }

  .preownr-detl {
    padding: 0 0 50px 0 !important;
  }

  .lookinfo h4 {
    font-size: 30px;
    line-height: 30px;
  }

  .lookdtl h4 {
    font-size: 35px;
  }

  .lookdtl h5 {
    font-size: 22px;
  }

  .lookdtl p {
    font-size: 15px;
  }

  .urgntrequire {
    margin: 40px 0 0;
  }

  .anglepic {
    top: 2%;
  }

  .preownr-detl {
    padding: 15px 0 !important;
  }

  .iconusp svg {
    width: 110px !important;
  }

  .uspfrst-add h5 {
    font-size: 30px;
    line-height: 36px;
  }

  .uspfrst-add h6 {
    font-size: 18px;
  }
  .uspadd-box {
    margin: 8px 0px;
  }

  .uspadd-box img {
    min-height: 300px;
  }

  .uspfrst-add img {
    min-height: inherit;
  }

  .uspfrst-add2 img {
    min-height: inherit;
  }

  .buy-sell-add .buybnr2 {
    margin-top: 50px;
  }
  .urgntrequire h3 {
    font-size: 32px;
  }
  .buycarinsu h4 {
    font-size: 34px;
  }
  .lookdtl h4 {
    font-size: 44px;
  }

  .work_image.dealers_img img {
    margin-left: 0px;
    margin-top: 0px;
  }
  .work_image.sellers_img img {
    margin: 70px 0px 0px;
  }

  .preownrusp-sec .container .row .col {
    flex: 1 0 100%;
  }
  .has-submenu .sub-menu {
    width: 100% !important;
  }

  #thumbs {
    margin-top: 15px;
    padding: 0 12px;
  }
  #thumbs .item {
    height: 55px;
  }
  .dtlspic {
    height: 200px;
  }
  .site_logo {
    max-width: 135px;
  }
  .carDisplay {
    min-height: 250px;
  }

  #thumbs {
    margin-top: 15px;
    padding: 0 12px;
  }
  #thumbs .item {
    height: 55px;
  }
  .dtlspic {
    height: 200px;
  }

  .inventory_section h1 {
    font-size: 30px;
    line-height: normal;
  }
  .about_content p {
    font-size: 15px;
    line-height: 25px;
  }
  .sortby-sec {
    display: grid;
    justify-content: center;
  }
  .sortby-sec {
    text-align: center;
  }

  .carservices-add .uspadd-box > img {
    min-height: 230px;
  }
  .carservices-add .uspfrst-add2 > img {
    max-width: 120px;
    min-height: inherit;
  }
  .carservices-add .uspfrst-add > img {
    max-width: 120px;
    min-height: inherit;
  }
  .iconusp svg {
    height: 90px;
  }

  .insurance-detl ul {
    text-align: left;
    padding: 0;
  }
  .types-insurance h2 {
    font-size: 32px;
    line-height: normal;
  }
  .inssurance-bx {
    margin: 10px 0;
  }
  .inssurance-bx p {
    font-size: 14px;
    line-height: 22px;
  }
  .types-insurance {
    padding: 30px 0 0;
  }
  .polici-sec h3 {
    font-size: 32px;
    line-height: normal;
  }
  .polici-bx {
    margin-bottom: 15px;
  }
  .adtxtshw-rght h4 {
    font-size: 37px;
  }
  .insuadinfo h5 {
    font-size: 35px;
  }
  .insuadinfo h4 {
    font-size: 26px;
    line-height: 30px;
  }
  .wrntycard span {
    width: 120px;
    height: 120px;
  }
  .wrntycard span img {
    max-width: 80px;
  }
  .how_works_section .work_heading h2 {
    font-size: 35px;
  }
  .banner_section > img {
    height: 488px;
  }
  .car-wrapper {
    left: 2px;
    top: -53px;
  }
  .stop1 {
    padding: 4px 7px 3px;
    font-size: 10px;
    top: -5px;
    /* left: 31.5%; */
    left: 28.5%;

  }
  .stop2 {
    padding: 4px 7px 3px;
    font-size: 10px;
    top: -5px;
    /* left: 49%; */
    left: 54%;
  }
  .stop3 {
    padding: 4px 7px 3px;
    font-size: 10px;
    top: -5px;
  }
  .siliguriarea {
    /* left: 53%; */
    left: 60%;
  }
  .map-wrapper::before {
    width: 68%;
  }
  .cararea {
    left: 36%;
    /* width: 18% !important; */
    width: 25% !important;

  }
  .kolarea {
    left: 36%;
  }
  .inventry-sec {
    padding: 30px 0 100px;
  }

  .banner_content {
    top: 2%;
    width: 100%;
    right: 2%;
    background: #292929;
    position: static;
    border-radius: 0;
  }
  .car-motion {
    position: absolute;
    bottom: auto;
    left: 0;
    right: 0;
    z-index: 1;
    top: 100px;
  }
  .form__email {
    padding: 15px 20px;
    font-size: 16px;
  }
  .form__button {
    width: 130px;
    height: 50px;
    font-size: 16px;
  }

}

@media only screen and (max-width: 480px) {
  /* .banner_content {
    top: 2%;
    width: 96%;
    right: 2%;
    background: #ffffff5c;
  } */
  .stop1 {
    /* display: none; */
  }
  .stop2 {
    /* display: none; */
  }
  .stop3 {
    /* display: none; */
  }
  .custommRow {
    padding: 20px 30px;
  }

  .BrandLogo::after {
    display: none;
  }

  .cartext h6 {
    font-size: 26px;
    line-height: 24px;
  }
  .cartext .headingtxt {
    font-size: 26px;
    line-height: 24px;
  }
  .displayText h4 {
    font-size: 28px;
  }
  .displayText .headingtxt {
    font-size: 28px;
  }
  .carDisplay {
    padding: 10px;
  }

  .about_image img {
    max-width: 60%;
    top: -30px;
  }

  .abp-con {
    max-width: 100% !important;
    top: 0px !important;
  }

  .fcar {
    display: none;
  }

  .footer_contact {
    flex-wrap: wrap;
  }

  .footer_contact .faddress {
    padding: 20px 15px;
    border-radius: 0px;
  }

  .accordion-body {
    font-size: 14px;
    line-height: 15px;
    padding: 10px 0px;
  }

  .faq_section {
    padding: 50px 0 100px;
  }

  .BrandLogo .heading h2 {
    font-size: 24px;
  }
  .Why-OtoBix .work_heading h1 {
    font-size: 30px;
  }
  .Why-OtoBix .work_heading h2 {
    font-size: 30px;
  }

  .about_boxes {
    flex-wrap: wrap;
  }

  .about_boxes .blue_box {
    margin-right: 0px;
    margin-bottom: 15px;
  }

  .about_boxes .blue_box,
  .about_boxes .red_box {
    width: 100%;
  }

  .about_image img {
    position: relative;
  }

  .team_content {
    margin-bottom: 10px;
  }

  .contactus.page_section {
    padding-bottom: 50px;
  }

  .contactusform {
    margin-bottom: 30px;
  }

  .terms_section.page_section {
    margin-bottom: 50px;
  }

  .terms_section h2 {
    font-size: 30px;
    line-height: 40px;
  }

  .terms_section.page_section {
    margin-bottom: 100px;
  }

  .page-heading {
    font-size: 50px;
  }

  .terms_section .inner_page {
    padding: 40px 0px 10px;
  }

  .faq_main_wrap .faq_section {
    margin-bottom: 50px;
  }

  .team_content .about_image img {
    width: 100%;
  }

  /******************about************************/
  .subbannertext h2,
  .subbannertext .bluee {
    font-size: 22px;
    margin: 0;
  }

  .LendingPartners ul li {
    width: 138px;
    height: 138px;
    margin: 12px;
  }

  .LendingPartners h4,
  .InsurancePartners h4 {
    font-size: 30px;
  }

  .InsurancePartners ul li {
    width: 150px;
    height: 117px;
  }

  .founder-nane {
    width: 92%;
    left: 4%;
  }

  /******************about************************/

  .suspendisse-sec .row > div {
    width: 100%;
    flex: 0 0 100%;
  }

  .suspendisse-sec .row > div:nth-child(even) {
    padding-top: 20px;
  }

  .suspendisse-sec .row > div:nth-child(even)::before {
    position: absolute;
    bottom: 10px;
    top: auto;
    left: 2px;
    right: -5px;
    height: 50%;
    border: 3px solid #40626c;
    content: "";
    border-radius: 25px;
    border-top: 0px;
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
  }
  .buy-sell-add .buybnr2 {
    margin-top: 50px;
  }
  .loking-loan img {
    max-width: 100%;
    min-height: 260px;
    object-fit: cover;
  }
  .lookinfo span img {
    max-width: 75%;
    min-height: inherit;
    object-fit: contain;
  }
  .lookdtl span img {
    max-width: 75%;
    min-height: inherit;
    object-fit: contain;
  }

  .preadvrtise > img {
    max-width: 100%;
    min-height: 260px;
    object-fit: cover;
  }

  .presell span img {
    max-width: 75%;
    min-height: inherit;
    object-fit: contain;
  }

  .work_image.dealers_img img {
    margin-left: 0px;
    margin-top: 0px;
  }
  .work_image.sellers_img img {
    margin: 70px 0px 0px;
  }
  .preownrusp-sec .container .row .col {
    flex: 1 0 100%;
  }
  .has-submenu .sub-menu {
    width: 100% !important;
    height: auto !important;
  }
  .text_testimonial {
    padding: 0 10px 0 0;
    margin-bottom: 30px;
  }
  .test_colm {
    border-right: none;
    margin-bottom: 20px;
  }
  .image_testimonial {
    padding-left: 0;
  }
  .image_testimonial .test_image {
    width: 60px;
  }
  .test_colm .test_desc_icon {
    padding: 0 0;
  }
  .lookinfo p {
    font-size: 15px;
    line-height: 16px;
  }
  .urgntrequire img {
    margin-bottom: 15px;
  }
  .car-wrapper img {
    width: 120px;
  }
  .whyOtoBix p {
    font-size: 18px;
  }

  .invtrydealers {
    height: 110px;
  }
}

@media only screen and (max-width: 380px) {

.car-motion {
  top: 90px;
}


}