.App {
  overflow: hidden;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

a:link {
  text-decoration: none !important;
}

.owl-dots {
  display: none !important;
}

.owl-prev {
  border-radius: 50% !important;
}

.owl-prev span {
  font-size: 12px !important;
}

.owl-next {
  border-radius: 50% !important;
}

.owl-next span {
  font-size: 12px !important;
}

#loader {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: #fff;
  color: var(--default);
  z-index: 999999;
  opacity: 1;
  pointer-events: none;
  transition: all 60s ease-in-out;
  /* opacity: 0.8; */
}

.flip-container {
  -webkit-perspective: 1000;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.ft_bottom_section .footercopyright {
  color: #a0a0a0;
}

.footer_menu {
  text-align: left;
}

.footer_contact {
  text-align: left;
}

.otobix_container {
  text-align: left;
}

.testimonial_section .testimonial_container {
  text-align: left;
}

.testimonial_slider {
  text-align: left;
}

.inventory_logo {
  width: 166px;
  height: 166px;
  border: 1px solid #000;
  border-radius: 10px;
  margin: 10px 0;
  cursor: pointer;
}

.inventory_logo img {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 166px;
  height: 166px;
  padding: 25px;
  border-radius: 10px;
  transition: all 0.3s ease-in;
}

.cursor-pointer {
  cursor: pointer;
}

.breadcrumbs {
  padding: 15px 0 13px;
  border-bottom: 1px solid #ccc;
  background: #fff;
}

.breadcrumbs p {
  margin-bottom: 0;
  text-align: left;
}

.breadcrumbs a {
  text-decoration: none;
  color: #000000;
  font-weight: 500;
}

.breadcrumbs i {
  padding: 0px 10px;
  color: #a0a0a0;
}

.breadcrumbs .breadcrumb_last {
  color: #bf1725;
  font-weight: 500;
  cursor: pointer;
}

.breadcrumbs a:hover {
  color: #bf1725;
}

.page-heading {
  font-style: normal;
  font-weight: 500;
  font-size: 45px;
  line-height: 85px;
  letter-spacing: -0.08em;
  text-transform: uppercase;
  color: #193f76;
}

.inner_page {
  padding: 10px 10px 10px 10px;
  text-align: center;
}

.contactus .inner_page {
  padding: 40px 0px;
  text-align: center;
}

.page_section p {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 30px;
  text-align: center;
  color: #273431;
}

.terms_section h2 {
  margin-bottom: 30px;
  font-size: 45px;
  line-height: 60px;
}

.terms_section .inner_page {
  padding: 10px 0px 10px;
  text-align: center;
}

.terms_section h2 {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 59px;
  line-height: 85px;
  letter-spacing: -0.08em;
  text-transform: capitalize;
  color: #000000;
  margin-bottom: 30px;
  text-align: center;
}

.terms_section ul {
  color: #000;
}
.terms_section ul li {
  margin: 5px 0;
}

.terms_section a {
  color: #f82f45;
}

.terms_section h3 {
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 40px;
  letter-spacing: -0.08em;
  color: #000;
  text-align: left !important;
}

.terms_section h4 {
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 35px;
  letter-spacing: -0.08em;
  text-transform: uppercase;
  color: #000;
}

.terms_section p {
  text-align: left !important;
  font-style: normal;
  font-weight: 500 !important;
}

.contactusform input {
  width: 100%;
  border-radius: 68px;
  border: 1px solid #979797;
  height: 50px;
  color: #989898;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 50px;
  margin: 12px 0;
  padding: 0 15px;
}

.contactusform textarea {
  width: 100%;
  border-radius: 40px;
  border: 1px solid #979797;
  height: 130px;
  color: #989898;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 50px;
  margin: 12px 0;
  padding: 0 15px;
}

.contactus .inner_page {
  padding: 10px 0px 10px;
}

.about_section .inner_page {
  padding: 10px 0px 10px !important;
}

.about_section .para_title {
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 30px;
  text-align: center;
}

.about_section .para_title .red {
  color: #f82f45;
}

.about_section .para_title .blue {
  color: #1c4075;
}

.about_boxes {
  justify-content: center;
  margin: 50px 0 60px;
  width: 100%;
  margin-top: 30px !important;
}

.align-items-center {
  align-items: center !important;
}

.about_boxes .blue_box,
.about_boxes .red_box {
  border-radius: 20px;
  padding: 30px;
  width: 30%;
}

.about_boxes .blue_box {
  background: #1c4075;
  margin-right: 30px;
}

.about_boxes .blue_box,
.about_boxes .red_box {
  border-radius: 20px;
  padding: 30px;
  width: 30%;
}

.about_boxes .red_box {
  background: #f82f45;
}

.about_boxes h3 {
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 52px;
  letter-spacing: -0.08em;
  text-transform: uppercase;
  color: #fff;
  position: relative;
  padding-bottom: 15px;
}

.about_boxes ul {
  list-style: none;
  padding: 0;
  text-align: left;
}

.about_boxes .blue_box ul li {
  border: 1px solid;
  border-image: linear-gradient(90deg, #305a99, #305a99) 1;
  border-right: 0;
  border-top: 0;
  border-left: 0;
}

.about_boxes .red_box ul li {
  border: 1px solid;
  border-image: linear-gradient(90deg, #ff6071, #ff6071) 1;
  border-right: 0;
  border-top: 0;
  border-left: 0;
}

.about_boxes ul li {
  display: flex;
  align-items: center;
}

.about_boxes ul li .box_image {
  display: inline-block;
  width: 55px;
}

.about_boxes ul li p {
  margin: 0;
  margin-left: 25px;
  width: 270px;
}

.about_boxes ul li p {
  font-weight: 500;
  font-size: 16px;
  line-height: 30px;
  color: #fff !important;
  padding: 20px 0;
  text-align: left;
  font-family: "Montserrat", sans-serif;
}

.about_section p {
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 26px !important;
  color: #273431 !important;
  font-family: "Montserrat", sans-serif;
}

.team_heading h2 {
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 85px;
  text-align: center;
  letter-spacing: -0.08em;
  text-transform: uppercase;
  color: #000;
}

.team_content {
  padding: 40px 0;
}

.faq_section h5 {
  font-style: normal;
  letter-spacing: -0.08em;
  color: #bf1725;
}

.faq_section .accordion-body p {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  color: #000;
  text-align: left;
}

.faq_section .accordion-body table {
  width: 100%;
  margin-bottom: 15px;
}

.faq_section .accordion-body table tr,
.accordion-body table td {
  border: 1px solid #000;
  padding: 10px;
}

.faq_section .accordion-body ol {
  text-align: left;
  padding: 12px 60px;
}

.faq_section .accordion-body ol,
li {
  line-height: 22px;
}

.faq_section .accordion-body ul li {
  margin-bottom: 20px;
  list-style: none;
  position: relative;
  padding: 0 30px;
  text-align: left;
}

.faq_inner_page {
  padding: 40px 0px 10px !important;
  font-size: 50px;
}

.faq_main_wrap .faq_section {
  padding: 10px 0 !important;
  position: relative;
}

.inventory_car {
  border: 1px solid;
  margin-bottom: 25px;
  background-color: #87ff8b;
  font-weight: 500;
}

.inventory_car .desc {
  display: flex;
  justify-content: space-between;
  padding: 0px 2px 2px 2px;
  font-size: 13px;
}

.headercontainer .navigation .navigationbox {
  float: left;
}

/* .headercontainer .container {
  max-width: 1350px;
} */

/* .checkInventory {
  width: 50% !important;
} */

.emptypage {
  margin-top: 300px;
}

.emiCalculateModal .modal-title {
  color: #f82f45;
}

.emiCalculateModal .calculatebtn {
  background: #1c4075;
  padding: 10px 20px;
  border: none;
  border-radius: 30px;
}

.emiCalculateModal .calculatebtn:hover {
  background: #f82f45;
}

.commonModal .modal-title {
  font-family: "Montserrat", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  color: #193f76;
}

.commonModal .submitbtn {
  background: #1c4075;
  padding: 10px 20px;
  border: none;
  border-radius: 30px;
}

.commonModal .submitbtn:hover {
  background: #f82f45;
}

.nodatafound {
  display: flex;
  justify-content: center;
}
.loadmorebtn {
  display: inline-block;
  border-radius: 68px;
  background: #f82f45;
  padding: 11px 20px;
  color: #fff;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  border: none;
  margin-top: 10px !important;
  width: 12% !important;
}
.loadmorebtn:hover {
  background: #1c4075;
}

.interestedbtn {
  text-align: center;
  margin-top: 15px;
}

.interestedbtn button {
  display: inline-block;
  align-items: center;
  border-radius: 68px;
  background: #f82f45;
  padding: 11px 20px;
  color: #fff;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  border: none;
  margin-top: 5px !important;
  display: inline-block;
}

.interestedbtn button:hover {
  background: #1c4075;
}

.reltedheading {
  display: flex;
  justify-content: space-between;
}

.invntryimg {
  width: 286px;
  height: 157px;
  object-fit: cover;
}

.invtrydealers {
  cursor: pointer;
  border: 1px solid #000;
  border-radius: 10px;
  height: 181px;
  /* width: 181px; */
  width: 100%;
  position: relative;
}
.invtrydealers img {
  max-width: 100%;
  width: auto !important;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  height: auto;
  max-height: 100%;
}

.invtrydealers .invtrydealersimage {
  border-radius: 10px;
}

.client_info .client_img {
  height: 60px;
  width: 60px;
}

.shimarcard {
  width: 100%;
  margin: 10px auto;
  padding: 20px;
}
@keyframes placeholderShimmer {
  0% {
    background-position: -700px 0;
  }
  100% {
    background-position: 700px 0;
  }
}
.shimmer-animation {
  background-color: #f6f7f8;
  background-image: linear-gradient(
    to right,
    #f6f7f8 0%,
    #edeef1 20%,
    #f6f7f8 40%,
    #f6f7f8 100%
  );
  background-repeat: no-repeat;
  background-size: 100% 100%;
  animation-duration: 1.3s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeholderShimmer;
  animation-timing-function: linear;
}
.loader-shimmer-banner {
  height: 16rem;
  background-size: 100% 18rem;
  border-radius: 5px;
  margin-bottom: 12px;
}
.loader-shimmer-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}
.loader-shimmer-title {
  height: 25px;
  flex: 0 0 auto;
  width: 80%;
}
.loader-shimmer-rating {
  height: 25px;
  flex: 0 0 auto;
  width: 60%;
}
.loader-shimmer-list {
  height: 20px;
  width: 60%;
  margin-bottom: 5px;
}
.loader-shimmer-list:last-child {
  margin-bottom: 0;
}
.loader-shimmer-info {
  height: 20px;
  width: 45%;
  margin-bottom: 5px;
}
.loader-shimmer-info:last-child {
  margin-bottom: 0;
}

.shimarbx {
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background: #fff;
  overflow: hidden;
  z-index: 9999;
  height: calc(100vh - 67px);
  margin-top: 67px;
}
.shimarbx::before {
  position: fixed;
  width: 100%;
  height: 67px;
  background: #fff;
  left: 0;
  top: 0;
  content: "";
}

@media only screen and (max-width: 575px) {
  .shimarbx {
    height: calc(100vh - 63px);
    margin-top: 63px;
  }
  .shimarbx::before {
    height: 63px;
  }
}

.mainbodyarea {
  min-height: 100vh !important;
}

/* ===== Scrollbar CSS ===== */
*::-webkit-scrollbar {
  width: 9px;
  height: 9px;
}

*::-webkit-scrollbar-thumb {
  border-radius: 10px;
  border: 3px solid #ffffff;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #b6b6b6;
}
